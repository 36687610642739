import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from '@/components/ui/dialog';
import { getOrderDetailsInvoiceOrderdetailsOrderIdGet } from '@/client/services.gen';
import { type OrderDetail } from '@/client/types.gen';
import { useQuery } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { format } from 'date-fns';
import { DataTable } from '@/components/ui/datatable/data-table';

interface OrderDetailsDialogProps {
    orderId: string | null;
    isOpen: boolean;
    onClose: () => void;
    charges?: {
        charge_on_invoice: string;
        quantity: number;
        amount: number;
    }[];
}

const AddressDisplay: React.FC<{ address: any; label: string }> = ({ address, label }) => (
    <div>
        <p className="text-sm text-gray-500">{label}</p>
        <div className="mt-1 space-y-1">
            <p>{address.address1}</p>
            {address.address2 && <p>{address.address2}</p>}
            <p>
                {address.city}, {address.state} {address.postal_code}
            </p>
            <p>{address.country}</p>
            {address.phone && <p>Phone: {address.phone}</p>}
            {address.email && <p>Email: {address.email}</p>}
        </div>
    </div>
);

const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = ({
    orderId,
    isOpen,
    onClose,
    charges
}) => {
    const { data: orderDetails, isLoading } = useQuery<OrderDetail>({
        queryKey: ['orderDetails', orderId],
        queryFn: async () => {
            if (!orderId) throw new Error('No order ID provided');
            const response = await getOrderDetailsInvoiceOrderdetailsOrderIdGet({
                path: { orderId },
            });
            return response.data as OrderDetail;
        },
        enabled: !!orderId,
    });

    const formatMeasurement = (value: any, unit: string) => {
        if (!value) return null;
        if (typeof value === 'object' && value.value !== undefined) {
            return `${Number(value.value).toFixed(2)} ${value.unit || unit}`;
        }
        return `${Number(value).toFixed(2)} ${unit}`;
    };

    const inventoryColumns = [
        {
            accessorKey: 'sku',
            header: 'SKU',
            enableColumnFilter: true,
            filterType: 'text' as const,
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.sku}</div>
            ),
        },
        {
            accessorKey: 'quantity',
            header: 'Quantity',
            enableColumnFilter: true,
            filterType: 'number' as const,
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <NumericFormat 
                        value={Number(row.getValue('quantity')).toFixed(2)} 
                        displayType="text" 
                        thousandSeparator="," 
                        decimalScale={2} 
                        fixedDecimalScale 
                    />
                </div>
            ),
        },
    ];

    const chargesColumns = [
        {
            accessorKey: 'charge_on_invoice',
            header: 'Charge Name',
            enableColumnFilter: true,
            filterType: 'text' as const,
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.charge_on_invoice}</div>
            ),
        },
        {
            accessorKey: 'quantity',
            header: 'Quantity',
            enableColumnFilter: true,
            filterType: 'number' as const,
            cell: ({ row }: { row: any }) => (
                <NumericFormat 
                    value={Number(row.getValue('quantity')).toFixed(2)} 
                    displayType="text" 
                    thousandSeparator="," 
                    decimalScale={2} 
                    fixedDecimalScale 
                />
            ),
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            enableColumnFilter: true,
            filterType: 'number' as const,
            cell: ({ row }: { row: any }) => (
                <NumericFormat 
                    value={Number(row.getValue('amount')).toFixed(2)} 
                    displayType="text" 
                    thousandSeparator="," 
                    prefix="$"
                    decimalScale={2} 
                    fixedDecimalScale 
                />
            ),
        },
    ];

    if (!orderId) return null;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="w-[90vw] max-w-[90vw] overflow-visible p-6">
                <DialogHeader className="pb-4">
                    <DialogTitle className="text-2xl font-bold text-rails-dark-blue">Order Details</DialogTitle>
                    <DialogDescription className="text-sm text-gray-500">
                        View detailed information about this order
                    </DialogDescription>
                </DialogHeader>
                <div className="max-h-[80vh] overflow-y-auto px-4 space-y-6">
                    {isLoading ? (
                        <div className="flex items-center justify-center p-4">
                            <div className="text-sm text-gray-500">Loading order details...</div>
                        </div>
                    ) : (
                        <div className="space-y-6">
                            {/* Order Information */}
                            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                <div className="p-4">
                                    <h3 className="text-xl font-semibold text-rails-dark-blue">Order Information</h3>
                                    <div className="mt-4 grid grid-cols-3 gap-4">
                                        {orderDetails?.order_number && (
                                            <div>
                                                <p className="text-sm text-gray-500">Order Number</p>
                                                <p className="mt-1">{orderDetails.order_number}</p>
                                            </div>
                                        )}
                                        {orderDetails?.order_date && (
                                            <div>
                                                <p className="text-sm text-gray-500">Order Date</p>
                                                <p className="mt-1">
                                                    {format(new Date(orderDetails.order_date), 'PPP')}
                                                </p>
                                            </div>
                                        )}
                                        {orderDetails?.tags && orderDetails.tags.length > 0 && (
                                            <div>
                                                <p className="text-sm text-gray-500">Tags</p>
                                                <div className="flex gap-2 flex-wrap mt-1">
                                                    {orderDetails.tags.map((tag, index) => (
                                                        <span key={index} className="bg-gray-100 px-2 py-1 rounded-md text-sm">
                                                            {tag}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Carrier Information */}
                            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                <div className="p-4">
                                    <h3 className="text-xl font-semibold text-rails-dark-blue">Shipping Details</h3>
                                    <div className="mt-4 grid grid-cols-2 gap-4">
                                        {orderDetails?.carrier_name && (
                                            <div>
                                                <p className="text-sm text-gray-500">Carrier</p>
                                                <p className="mt-1">{orderDetails.carrier_name}</p>
                                            </div>
                                        )}
                                        {orderDetails?.service_level_name && (
                                            <div>
                                                <p className="text-sm text-gray-500">Service Level</p>
                                                <p className="mt-1">{orderDetails.service_level_name}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Shipping Information */}
                            {orderDetails?.shipments && orderDetails.shipments.length > 0 && (
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                    <div className="p-4">
                                        <h3 className="text-xl font-semibold text-rails-dark-blue">Shipments</h3>
                                        <div className="mt-4 space-y-6">
                                            {orderDetails.shipments.map((shipment, index) => (
                                                <div key={index} className="border-t first:border-t-0 pt-4 first:pt-0">
                                                    <h4 className="text-lg font-medium text-rails-dark-blue mb-4">Shipment {index + 1}</h4>
                                                    <div className="grid grid-cols-2 gap-6">
                                                        <AddressDisplay address={shipment.ship_from_address} label="Ship From" />
                                                        <AddressDisplay address={shipment.ship_to_address} label="Ship To" />
                                                        <div>
                                                            <p className="text-sm text-gray-500">Shipped Date</p>
                                                            <p className="mt-1">
                                                                {shipment.shipped_date && format(new Date(shipment.shipped_date), 'PPP')}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-sm text-gray-500">Return Shipment</p>
                                                            <p className="mt-1">{shipment.is_return ? 'Yes' : 'No'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Package Information */}
                            {orderDetails?.packages && orderDetails.packages.length > 0 && 
                             orderDetails.packages.some(pkg => 
                                pkg.tracking_number || 
                                (pkg.shipping_cost && pkg.shipping_cost !== 0)
                             ) && (
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                    <div className="p-4">
                                        <h3 className="text-xl font-semibold text-rails-dark-blue">Packages</h3>
                                        <div className="mt-4 space-y-6">
                                            {orderDetails.packages.map((pkg, index) => (
                                                <div key={index} className="border-t first:border-t-0 pt-4 first:pt-0">
                                                    <h4 className="text-lg font-medium text-rails-dark-blue mb-4">Package {index + 1}</h4>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        {pkg.tracking_number && (
                                                            <div>
                                                                <p className="text-sm text-gray-500">Tracking Number</p>
                                                                <p className="mt-1">{pkg.tracking_number}</p>
                                                            </div>
                                                        )}
                                                        {pkg.shipping_cost && (
                                                            <div>
                                                                <p className="text-sm text-gray-500">Shipping Cost</p>
                                                                <p className="mt-1">
                                                                    <NumericFormat
                                                                        value={Number(pkg.shipping_cost).toFixed(2)}
                                                                        displayType="text"
                                                                        thousandSeparator=","
                                                                        prefix="$"
                                                                        decimalScale={2}
                                                                        fixedDecimalScale
                                                                    />
                                                                </p>
                                                            </div>
                                                        )}
                                                        {pkg.measurements && (
                                                            <div>
                                                                <p className="text-sm text-gray-500">Measurements</p>
                                                                <div className="mt-1 space-y-1">
                                                                    {formatMeasurement(pkg.measurements.weight, 'lbs') && (
                                                                        <p>Weight: {formatMeasurement(pkg.measurements.weight, 'lbs')}</p>
                                                                    )}
                                                                    {formatMeasurement(pkg.measurements.length, 'in') && (
                                                                        <p>Length: {formatMeasurement(pkg.measurements.length, 'in')}</p>
                                                                    )}
                                                                    {formatMeasurement(pkg.measurements.width, 'in') && (
                                                                        <p>Width: {formatMeasurement(pkg.measurements.width, 'in')}</p>
                                                                    )}
                                                                    {formatMeasurement(pkg.measurements.height, 'in') && (
                                                                        <p>Height: {formatMeasurement(pkg.measurements.height, 'in')}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Inventory Items */}
                            {orderDetails?.inventory_items && orderDetails.inventory_items.length > 0 && (
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                    <div className="p-4">
                                        <h3 className="text-xl font-semibold text-rails-dark-blue">Shipped Items</h3>
                                        <div className="mt-4">
                                            <DataTable
                                                data={orderDetails.inventory_items}
                                                columns={inventoryColumns}
                                                loading={false}
                                                showPagination={true}
                                                showToolBar={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Invoice Line Items Section */}
                            {charges && charges.length > 0 && (
                                <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                                    <div className="p-4">
                                        <h3 className="text-xl font-semibold text-rails-dark-blue">Invoice Line Items</h3>
                                        <div className="mt-4">
                                            <DataTable
                                                data={charges}
                                                columns={chargesColumns}
                                                loading={false}
                                                showPagination={true}
                                                showToolBar={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default OrderDetailsDialog;
