import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import LoadingSpinner from '@/components/ui/loading-spinner';
import {
    getInvoiceSummaryInvoiceInvoiceSummariesGet,
    getInvoiceInvoicesDeliveryFileInvoiceIdGet,
} from '@/client/services.gen';
import { readManyQbInvoicesGet } from '@/client/services.gen';
import {
    MagnifyingGlassIcon,
    ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';

interface Invoice {
    customer_name: string;
    invoice_id: string;
    billing_period_start: string;
    billing_period_end: string;
    total_invoice_amount: number;
    status: string;
    voided_at: string | null;
    quickbooks_balance?: number;
}

interface InvoiceStats {
    weeklyInvoiceCount: number;
    weeklyInvoiceCountChange: number;
    totalInvoicedAmount: number;
    totalInvoicedAmountChange: number;
    openInvoicesCount: number;
    openInvoicesTotalAmount: number;
}

const Invoices = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [downloadingInvoiceId, setDownloadingInvoiceId] = useState<
        string | null
    >(null);
    const [invoiceTableData, setInvoiceTableData] = useState<Invoice[]>([]);
    const [invoiceStats, setInvoiceStats] = useState<InvoiceStats>({
        weeklyInvoiceCount: 0,
        weeklyInvoiceCountChange: 0,
        totalInvoicedAmount: 0,
        totalInvoicedAmountChange: 0,
        openInvoicesCount: 0,
        openInvoicesTotalAmount: 0,
    });

    const { brandId } = useParams<{ brandId: string }>();

    const fetchInvoices = async () => {
        try {
            const [invoicesResponse, qbInvoicesResponse] = await Promise.all([
                getInvoiceSummaryInvoiceInvoiceSummariesGet({
                    query: { customerId: brandId },
                }),
                readManyQbInvoicesGet(),
            ]);

            const qbBalanceMap = qbInvoicesResponse.data?.reduce(
                (acc: { [key: string]: number }, qbInv: any) => {
                    if (
                        qbInv.invoice_id &&
                        qbInv.quickbooks_balance !== undefined
                    ) {
                        acc[qbInv.invoice_id] = qbInv.quickbooks_balance;
                    }
                    return acc;
                },
                {},
            );

            // Join the QB balance data with the invoice data
            const sortedData = invoicesResponse
                .data!.filter(
                    (invoice) =>
                        invoice.status !== 'void' &&
                        invoice.voided_at === null &&
                        invoice.status !== 'open',
                )
                .map((invoice) => ({
                    ...invoice,
                    quickbooks_balance: qbBalanceMap[invoice.invoice_id],
                }))
                .sort((a: Invoice, b: Invoice) => {
                    const dateA = new Date(a.billing_period_end);
                    const dateB = new Date(b.billing_period_end);
                    if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                        return dateB.getTime() - dateA.getTime();
                    }
                    return 0;
                });

            setInvoiceTableData(sortedData);

            // Calculate invoice stats
            const now = new Date();
            const oneWeekAgo = new Date(
                now.getTime() - 7 * 24 * 60 * 60 * 1000,
            );
            const twoWeeksAgo = new Date(
                now.getTime() - 14 * 24 * 60 * 60 * 1000,
            );

            const thisWeekInvoices = sortedData.filter(
                (invoice) => new Date(invoice.billing_period_end) >= oneWeekAgo,
            );
            const lastWeekInvoices = sortedData.filter(
                (invoice) =>
                    new Date(invoice.billing_period_end) >= twoWeeksAgo &&
                    new Date(invoice.billing_period_end) < oneWeekAgo,
            );

            const weeklyInvoiceCount = thisWeekInvoices.length;
            const lastWeekInvoiceCount = lastWeekInvoices.length;
            const weeklyInvoiceCountChange =
                ((weeklyInvoiceCount - lastWeekInvoiceCount) /
                    lastWeekInvoiceCount) *
                100;

            const totalInvoicedAmount = thisWeekInvoices.reduce(
                (sum, invoice) =>
                    sum + parseFloat(invoice.total_invoice_amount.toString()),
                0,
            );
            const lastWeekTotalInvoicedAmount = lastWeekInvoices.reduce(
                (sum, invoice) =>
                    sum + parseFloat(invoice.total_invoice_amount.toString()),
                0,
            );
            const totalInvoicedAmountChange =
                lastWeekTotalInvoicedAmount !== 0
                    ? ((totalInvoicedAmount - lastWeekTotalInvoicedAmount) /
                          lastWeekTotalInvoicedAmount) *
                      100
                    : totalInvoicedAmount > 0
                      ? 100
                      : 0;

            const openInvoices = sortedData.filter(
                (invoice) => invoice.status === 'open',
            );
            const openInvoicesCount = openInvoices.length;
            const openInvoicesTotalAmount = openInvoices.reduce(
                (sum, invoice) =>
                    sum + parseFloat(invoice.total_invoice_amount.toString()),
                0,
            );

            setInvoiceStats({
                weeklyInvoiceCount,
                weeklyInvoiceCountChange: isNaN(weeklyInvoiceCountChange)
                    ? 0
                    : weeklyInvoiceCountChange,
                totalInvoicedAmount,
                totalInvoicedAmountChange: isNaN(totalInvoicedAmountChange)
                    ? 0
                    : totalInvoicedAmountChange,
                openInvoicesCount,
                openInvoicesTotalAmount,
            });
        } catch (error) {
            console.error('Error fetching invoice data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchInvoices()]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const handleDownload = async (invoice: Invoice) => {
        setDownloadingInvoiceId(invoice.invoice_id);
        await getInvoiceInvoicesDeliveryFileInvoiceIdGet({
            path: { invoice_id: invoice.invoice_id },
        })
            .then((res) => {
                const tempLink = document.createElement('a');
                tempLink.href = res.data!.file_url;
                tempLink.setAttribute(
                    'download',
                    `detailed_invoice_report.csv`,
                );
                tempLink.click();
            })
            .finally(() => setDownloadingInvoiceId(null));
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <div className="flex flex-col items-center gap-2">
                    <LoadingSpinner loading={true} className="h-8 w-8" />
                    <div className="text-sm text-gray-500">Loading data...</div>
                </div>
            </div>
        );
    }

    const invoiceColumns = [
        {
            accessorKey: 'invoice_id',
            header: 'Invoice ID',
            cell: ({ row }: { row: any }) => (
                <span className="max-w-[500px] text-wrap">
                    {row.original.invoice_id}
                </span>
            ),
            enableSorting: false,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            filterType: 'text',
            enableColumnFilter: true,
            cell: ({ row }: { row: any }) => (
                <span className="max-w-[500px] text-wrap">
                    {row.original.voided_at !== null ? (
                        'void'
                    ) : row.original.status === 'pending' ? (
                        <LoadingSpinner loading={true} />
                    ) : (
                        row.original.status
                    )}
                </span>
            ),
            enableSorting: false,
        },
        {
            accessorKey: 'billing_period_start',
            header: 'Billing Period Start',
            filterType: 'date',
            enableColumnFilter: true,
            cell: ({ row }: { row: any }) => (
                <span className="max-w-[500px] text-wrap">
                    {row.getValue('billing_period_start').substring(0, 10)}
                </span>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'billing_period_end',
            header: 'Billing Period End',
            filterType: 'date',
            enableColumnFilter: true,
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        {row.getValue('billing_period_end').substring(0, 10)}
                    </span>
                </div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'total_invoice_amount',
            header: 'Total Invoice Amount',
            filterType: 'number',
            enableColumnFilter: true,
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat
                            value={row.getValue('total_invoice_amount')}
                            displayType="text"
                            thousandSeparator=","
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </span>
                </div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: 'quickbooks_balance',
            header: 'Outstanding Balance',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        {row.getValue('quickbooks_balance') !== undefined ? (
                            <NumericFormat
                                value={row.getValue('quickbooks_balance')}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        ) : (
                            '-'
                        )}
                    </span>
                </div>
            ),
            enableSorting: true,
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }: { row: any }) => (
                <div className="flex items-center space-x-2">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(row.original);
                        }}
                        className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:hover:bg-transparent"
                        title="Download Invoice"
                        disabled={downloadingInvoiceId !== null}
                    >
                        {downloadingInvoiceId === row.original.invoice_id ? (
                            <LoadingSpinner
                                loading={true}
                                className="h-5 w-5"
                            />
                        ) : (
                            <ArrowDownTrayIcon className="h-5 w-5 text-gray-600" />
                        )}
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            //handleAnalyze(row.original);
                        }}
                        className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:hover:bg-transparent"
                        title="Analyze Invoice"
                        disabled={downloadingInvoiceId !== null}
                    >
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-600" />
                    </button>
                </div>
            ),
            enableSorting: false,
        },
    ];

    return (
        <div className="container mx-auto p-8">
            <div className="grid grid-cols-2 gap-4 mb-8">
                <Card>
                    <CardHeader>
                        <CardTitle>Open Invoices</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">
                            {invoiceStats.openInvoicesCount}
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>Outstanding Amount</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-3xl font-bold">
                            <NumericFormat
                                value={invoiceStats.openInvoicesTotalAmount}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </p>
                    </CardContent>
                </Card>
            </div>
            {invoiceTableData ? (
                <DataTable
                    loading={isLoading}
                    data={invoiceTableData}
                    columns={invoiceColumns}
                    isMultiSelectRows={false}
                    showActionButtonInToolbar
                    isDownloadable={true}
                    downloadData={invoiceTableData.map((invoice) => ({
                        invoice_id: invoice.invoice_id,
                        customer_name: invoice.customer_name,
                        billing_period_start: invoice.billing_period_start,
                        billing_period_end: invoice.billing_period_end,
                        total_invoice_amount: invoice.total_invoice_amount,
                    }))}
                    tableName="invoices"
                />
            ) : null}
        </div>
    );
};

export default Invoices;
