import { useState, useEffect } from 'react';
import type { ChargeType, GetAlertsConfigAlertsGetResponse } from '@/client';
import { getAlertsConfigAlertsGet, deleteAlertConfigAlertsAlertIdDelete } from '@/client';
import LoadingSpinner from '@/components/ui/loading-spinner.tsx';
import ChargeTypeAlertForm from './forms/chargeTypeAlertForm';
import { Button } from '@/components/ui/button';

export default function ChargeTypeConfig({
  chargeType
}: {
    chargeType: ChargeType
  }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<GetAlertsConfigAlertsGetResponse>([])
  const [showAlertForm, setShowAlertForm] = useState(false)

  useEffect(() => {
    fetchAlerts() 
  }, [])

  const fetchAlerts = async () => {
    setIsLoading(true);
      try {
          const { data, error } = await getAlertsConfigAlertsGet();
          if (error) {
              console.error(error);
              return;
          }
          if (data) {
              setAlerts(data);
          }
      } finally {
          setIsLoading(false);
      }
  }

  const matchingAlerts = alerts.filter(alert => alert.resource_uuid === chargeType.id);

  const handleDeleteAlert = async (alertId: string) => {
    setIsLoading(true);
    try {
      await deleteAlertConfigAlertsAlertIdDelete({ path: { alert_id: alertId } })
      fetchAlerts();
    } catch (error) {
      console.error('Error deleting alert:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpinner className="ml-2 h-4 w-4" loading={isLoading} />}

      {showAlertForm ? (
        <ChargeTypeAlertForm
          chargeType={chargeType}
          onClose={() => setShowAlertForm(false)}
          onSubmit={() => {
            setShowAlertForm(false);
            fetchAlerts();
          }}
        />
      ) : (
        <div className="space-y-4">
          <Button variant="outline" size="sm" onClick={() => setShowAlertForm(true)}>
            Add Alert
          </Button>
          
          {matchingAlerts.length > 0 ? (
            <div className="space-y-3">
              {matchingAlerts.map((alert) => (
                <div key={alert.id} className="rounded-lg border border-border p-4 shadow-sm">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <div className="flex items-center space-x-2">
                        <span className="font-medium">
                          {alert.alert_config.operator as string} {alert.alert_config.amount as string}
                        </span>
                      </div>
                      <div className="text-sm text-muted-foreground">
                        {alert.alert_config.apply_to_sum as string && 
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-2">
                            Applied to sum of line items
                          </span>
                        }
                        {alert.alert_config.apply_to_line_item as string && 
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs text-green-700 ring-1 ring-inset ring-green-700/10">
                            Applied to matching line item
                          </span>
                        }
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="text-destructive hover:text-destructive/90"
                      onClick={() => handleDeleteAlert(alert.id as string)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-sm text-muted-foreground">No alerts found</div>
          )}
        </div>
      )}
    </div>
  )
}
