import { useState } from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import { BanknotesIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from '@/components/auth/AuthProvider';
import logo from '@/assets/logo_only_green_on_transparent.png';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Button } from '@/components/ui/button';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const NavBarInvoicePortal = () => {
    const location = useLocation();
    const { currentUser, currentUserCompany, currentUserBrand } = useAuth();

    const navBarOptions = [
        {
            name: 'Invoices',
            href: '/portal/' + currentUserBrand + '/invoices',
            icon: BanknotesIcon,
        },
    ];

    const [open, setOpen] = useState(() =>
        navBarOptions.reduce(
            (obj, item) => ({
                ...obj,
                [item.name]: location.pathname.includes(
                    item.name.toLowerCase(),
                ),
            }),
            {},
        ),
    );

    return (
        <>
            {currentUser && (
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-16 w-auto mt-4"
                                src={logo}
                                alt="Rails"
                            />
                        </div>
                        <nav className="flex flex-1 flex-col px-6 mt-2">
                            <ul className="flex flex-1 flex-col gap-y-3">
                                {navBarOptions &&
                                    navBarOptions.map((option) =>
                                        option.children ? (
                                            <li key={option.name}>
                                                <Collapsible
                                                    className="w-full"
                                                    open={open[option.name]}
                                                    onOpenChange={(val) => {
                                                        setOpen({
                                                            ...open,
                                                            [option.name]: val,
                                                        });
                                                    }}
                                                >
                                                    <CollapsibleTrigger
                                                        className="w-full"
                                                        asChild
                                                    >
                                                        <Button
                                                            variant={
                                                                open[
                                                                    option.name
                                                                ] ||
                                                                location.pathname.includes(
                                                                    option.name.toLowerCase(),
                                                                )
                                                                    ? 'outline'
                                                                    : 'ghost'
                                                            }
                                                            className={classNames(
                                                                location.pathname.includes(
                                                                    option.name.toLowerCase(),
                                                                )
                                                                    ? 'text-rails-dark-green bg-gray-100'
                                                                    : 'text-gray-700 hover:text-rails-dark-green hover:bg-gray-100',
                                                                'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-normal',
                                                            )}
                                                        >
                                                            <option.icon
                                                                className={classNames(
                                                                    location.pathname.includes(
                                                                        option.name.toLowerCase(),
                                                                    )
                                                                        ? 'text-rails-dark-green'
                                                                        : 'text-gray-400 group-hover:text-rails-dark-green',
                                                                    'h-5 w-5 shrink-0',
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {option.name}
                                                            <ChevronRightIcon
                                                                className={classNames(
                                                                    open[
                                                                        option
                                                                            .name
                                                                    ]
                                                                        ? 'rotate-90 text-gray-500'
                                                                        : 'text-gray-400',
                                                                    'ml-auto h-4 w-4 shrink-0',
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </Button>
                                                    </CollapsibleTrigger>
                                                    <CollapsibleContent className="pl-4">
                                                        <ul>
                                                            {option.children.map(
                                                                (
                                                                    childOption,
                                                                ) => (
                                                                    <li
                                                                        className="mt-1"
                                                                        key={
                                                                            childOption.name
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={
                                                                                childOption.href
                                                                            }
                                                                            className={classNames(
                                                                                location.pathname ===
                                                                                    childOption.href
                                                                                    ? 'bg-rails-dark-green text-white'
                                                                                    : 'text-gray-700 hover:text-rails-dark-green hover:bg-gray-100',
                                                                                'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-normal',
                                                                            )}
                                                                        >
                                                                            <childOption.icon
                                                                                className={classNames(
                                                                                    location.pathname ===
                                                                                        childOption.href
                                                                                        ? 'text-white'
                                                                                        : 'text-gray-400 group-hover:text-rails-dark-green',
                                                                                    'ml-4 h-5 w-5 shrink-0',
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                            {
                                                                                childOption.name
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                ),
                                                            )}
                                                        </ul>
                                                    </CollapsibleContent>
                                                </Collapsible>
                                            </li>
                                        ) : (
                                            <li key={option.name}>
                                                <Link
                                                    to={option.href}
                                                    className={classNames(
                                                        location.pathname ===
                                                            option.href
                                                            ? 'bg-rails-dark-green text-white'
                                                            : 'text-gray-700 hover:text-rails-dark-green hover:bg-gray-100',
                                                        'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-normal',
                                                    )}
                                                >
                                                    <option.icon
                                                        className={classNames(
                                                            location.pathname ===
                                                                option.href
                                                                ? 'text-white'
                                                                : 'text-gray-400 group-hover:text-rails-dark-green',
                                                            'h-5 w-5 shrink-0',
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {option.name}
                                                </Link>
                                            </li>
                                        ),
                                    )}
                                {/* TODO: Make portal specific settings */}
                                <li className="my-4 mt-auto">
                                    <Link to="/settings">
                                        <div
                                            className={classNames(
                                                location.pathname ===
                                                    '/settings'
                                                    ? 'text-white bg-rails-dark-green'
                                                    : 'text-gray-700 hover:bg-gray-200',
                                                'flex group rounded-md text-sm font-normal p-2 pl-4 pr-4 items-center text-rails-dark-green bg-gray-100',
                                            )}
                                        >
                                            <div className="gap-x-3 items-center">
                                                <UserCircleIcon
                                                    className={classNames(
                                                        location.pathname ===
                                                            '/settings'
                                                            ? 'text-white'
                                                            : 'text-gray-400',
                                                        'h-10 w-10 shrink-0 text-rails-dark-green',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="ml-4">
                                                <div>
                                                    {currentUser.displayName}
                                                </div>
                                                {currentUserCompany && (
                                                    <div className="text-gray-400">
                                                        {currentUserCompany}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
            <div className="py-2 lg:pl-80">
                <Outlet />
            </div>
        </>
    );

    // );
};

export default NavBarInvoicePortal;
