import { useState, useRef, useEffect } from 'react';
import { useToast } from '../../common/use-toast.tsx';
import type { UOM } from '@/client/types.gen.ts';
import { CircleCheck, CirclePlus, X, XCircle, Upload, Download } from 'lucide-react';
import {
    createV2UomsPost,
    deleteV2UomsUomIdDelete,
    downloadUomProductLinksV2UomsProductsDownloadGet,
    readV2UomsGet,
    readManyV2WmsCustomersGet,
    uploadUomProductLinksV2UomsProductsUploadPost,
} from '@/client/services.gen.ts';
import { Input } from '@/components/ui/input.tsx';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card.tsx';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button.tsx';
import Select from 'react-select';
import LoadingSpinner from '@/components/ui/loading-spinner.tsx';

interface CustomerOption {
    value: string;
    label: string;
}

const UomConfigDialog = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uomConfigData, setUomConfigData] = useState<Array<UOM>>([]);
    const [inputIsVisible, setInputIsVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { toast } = useToast();
    const [customers, setCustomers] = useState<CustomerOption[]>([]);
    const [selectedCustomers, setSelectedCustomers] = useState<CustomerOption[]>([]);
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState(false);

    const fetchUomConfig = async () => {
        setIsLoading(true);
        try {
            const { data, error } = await readV2UomsGet();
            if (error) {
                console.error(error);
                return;
            }
            if (data) {
                setUomConfigData(data);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            await Promise.all([fetchUomConfig(), fetchCustomers()]);
        };
        initialize();
    }, []);

    const addUom = async () => {
        if (inputValue.length === 0) {
            toast({
                variant: 'destructive',
                title: 'Unit of Measure name cannot be empty.',
            });
            return;
        }

        const options = { query: { name: inputValue } };
        try {
            setIsLoading(true);
            await createV2UomsPost(options);
            await fetchUomConfig();
            toast({
                variant: 'default',
                title: 'Unit of Measure added successfully.',
            });
            setInputIsVisible(false);
            setInputValue('');
        } catch (e) {
            toast({
                variant: 'destructive',
                title: 'Failed to add Unit of Measure.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUom = async (id: string | undefined) => {
        if (!id) {
            return;
        }
        const options = { path: { uom_id: id } };
        try {
            setIsLoading(true);
            await deleteV2UomsUomIdDelete(options);
            fetchUomConfig();
            toast({
                variant: 'default',
                title: 'Unit of Measure deleted successfully.',
            });
        } catch (e) {
            toast({
                variant: 'destructive',
                title: 'Failed to delete Unit of Measure.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getFilename = () => {
        const date = new Date();

        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are 0-indexed
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const formattedDate = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}-${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}`;

        return `rails_product_uoms_${formattedDate}.csv`;
    };

    const fetchCustomers = async () => {
        const { data, error } = await readManyV2WmsCustomersGet();
        if (error) {
            console.error(error);
            return;
        }
        if (data) {
            const customerOptions = data.map((customer) => ({
                value: customer.id,
                label: customer.name,
            }));
            setCustomers(customerOptions);
        }
    };

    const handleCsvDownload = async (selectedCustomers) => {
        setIsLoading(true);
        try {
            const customerIds = selectedCustomers.map((c) => c.value);
            const response = await downloadUomProductLinksV2UomsProductsDownloadGet({
                query: { customers: customerIds },
            });

            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = getFilename();
            a.click();
            window.URL.revokeObjectURL(url);
            setShowCustomerDialog(false);
            setSelectedCustomers([]);

            toast({
                variant: 'default',
                title: 'Download successful.',
            });
        } catch (e) {
            console.error(e);
            toast({
                variant: 'destructive',
                title: 'Failed to download CSV.',
            });
        }
        setIsLoading(false);
    };

    const handleCancelInput = () => {
        setInputIsVisible(false);
        setInputValue('');
    };

    const handleShowInput = () => {
        setInputIsVisible(true);
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setIsUploading(true);
        try {
            const { error } = await uploadUomProductLinksV2UomsProductsUploadPost({
                body: { file },
            });

            if (error) {
                throw error;
            }

            toast({
                variant: 'default',
                title: 'File uploaded successfully.',
            });
        } catch (error) {
            console.error(error);
            toast({
                variant: 'destructive',
                title: 'Failed to upload file.',
            });
        } finally {
            setIsUploading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    return (
        <div className="pt-4">
            <div className="container mx-auto py-4">
                <Card className="w-full">
                    <CardHeader>
                        <CardTitle className="text-lg font-medium text-gray-900 flex items-center">
                            Billable Unit of Measures for Products
                            {isLoading && <LoadingSpinner className="ml-2 h-4 w-4" loading={isLoading} />}
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex gap-1">
                            {uomConfigData?.map((uom) => (
                                <div key={uom?.id} className="flex items-center gap-x-1">
                                    <div className="p-3 bg-gray-200 text-gray-600 rounded-sm">{uom.name}</div>
                                    <Dialog>
                                        <DialogTrigger>
                                            <X className="cursor-pointer text-gray-500 hover:bg-red-600 hover:text-white" size={20} />
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle>Delete {uom.name}</DialogTitle>
                                                <DialogClose />
                                            </DialogHeader>
                                            <DialogDescription>
                                                Are you sure you want to remove {uom.name} as a Unit of Measure? This action will delete all the conversions set up for it.
                                            </DialogDescription>
                                            <DialogFooter>
                                                <Button onClick={() => deleteUom(uom?.id)}>Delete</Button>
                                            </DialogFooter>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            ))}
                            {inputIsVisible && (
                                <div className="flex gap-x-1 px-1 text-gray-600  rounded-sm">
                                    <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} type="text" placeholder="input ..." className="w-32 px-2" />
                                    <CircleCheck className="self-center cursor-pointer text-gray-500 hover:text-green-500" size={20} onClick={addUom} />
                                    <XCircle className="self-center cursor-pointer text-gray-500 hover:text-red-500" size={20} onClick={handleCancelInput} />
                                </div>
                            )}
                            {!inputIsVisible && <CirclePlus className="self-center cursor-pointer text-gray-500 hover:text-green-500" size={20} onClick={handleShowInput} />}
                        </div>
                        <div className="flex justify-start gap-2 mt-8">
                            <Button onClick={() => setShowCustomerDialog(true)} disabled={isLoading}>
                                <Download className="w-4 h-4 mr-2" />
                                Download Template
                            </Button>
                            <input type="file" ref={fileInputRef} onChange={handleFileUpload} accept=".csv" className="hidden" />
                            <Button onClick={() => fileInputRef.current?.click()} disabled={isLoading || isUploading} variant="outline">
                                {isUploading ? (
                                    <>
                                        <span className="animate-spin mr-2">⏳</span>
                                        Uploading...
                                    </>
                                ) : (
                                    <>
                                        <Upload className="w-4 h-4 mr-2" />
                                        Upload CSV
                                    </>
                                )}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <Dialog open={showCustomerDialog} onOpenChange={setShowCustomerDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Select Customers</DialogTitle>
                        <DialogDescription>Select customers to filter the download (optional)</DialogDescription>
                    </DialogHeader>
                    <Select
                        isMulti
                        options={customers}
                        value={selectedCustomers}
                        onChange={(selected) => setSelectedCustomers(selected as CustomerOption[])}
                        className="mt-2"
                        placeholder="Select customers..."
                    />
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowCustomerDialog(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleCsvDownload(selectedCustomers)}>Download</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UomConfigDialog;
