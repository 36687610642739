import { useState, useEffect } from 'react';
import { getInvoiceChargeTypesGet } from '@/client';
import type { ChargeType } from '@/client';
import ChargeTypeConfig from './chargeTypeConfig';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card.tsx';
import LoadingSpinner from '@/components/ui/loading-spinner.tsx';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";

export default function ChargeTypeAlerts() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chargeTypes, setChargeTypes] = useState<ChargeType[]>([])

  useEffect(() => {
    fetchChargeTypes()
  }, [])

  const fetchChargeTypes = async () => {
      setIsLoading(true);
      try {
          const { data, error } = await getInvoiceChargeTypesGet();
          if (error) {
              console.error(error);
              return;
          }
          if (data) {
              setChargeTypes(data);
          }
      } finally {
          setIsLoading(false);
      }
  };

  return <div className="container mx-auto py-4">
    <Card className="w-full">
        <CardHeader>
            <CardTitle className="text-lg font-medium text-gray-900 flex items-center">
                Charge Type Alerts
                {isLoading && <LoadingSpinner className="ml-2 h-4 w-4" loading={isLoading} />}
            </CardTitle>
        </CardHeader>
        <CardContent>
            <div className="w-full">
                {isLoading ? (
                    <LoadingSpinner className="h-4 w-4 mr-2" loading={isLoading} />
                ) : (
                    <DataTable
                        data={chargeTypes}
                        columns={[
                            {
                                accessorKey: 'name',
                                header: 'Name',
                                cell: ({ row }: { row: any }) => (
                                    <div className="font-medium">{row.getValue('name')}</div>
                                ),
                            },
                            {
                                accessorKey: 'charge_level',
                                header: 'Charge Level',
                                cell: ({ row }: { row: any }) => (
                                    <div>{row.getValue('charge_level')}</div>
                                ),
                            },
                            {
                                id: 'actions',
                                cell: ({ row }: { row: any }) => (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button variant="outline" size="sm">
                                                Manage Alerts
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle className="break-words max-w-full line-clamp-2 leading-relaxed">Manage Alerts for {row.getValue('name')}</DialogTitle>
                                                <DialogDescription>Manage Charge Type Alerts</DialogDescription>
                                            </DialogHeader>
                                            {/* Dialog content will go here */}
                                            <ChargeTypeConfig chargeType={row.original} />
                                        </DialogContent>
                                    </Dialog>
                                ),
                            }
                        ]}
                        isMultiSelectRows={false}
                        showActionButtonInToolbar={false}
                        showPagination={true}
                        showToolBar={true}
                        tableName="charge-types"
                    />
                )}
            </div>
        </CardContent>
    </Card>
  </div>
}
