import {
    Form,
    FormField,
    FormItem,
    FormControl,
    FormMessage,
    FormLabel,
} from '@/components/ui/form';
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from '@/components/ui/input';
import Select from 'react-select';
import type { ChargeType } from '@/client';
import { postConfigAlertsPost } from '@/client';
import type { PostConfigAlertsPostData } from '@/client';

const operators = [
    { value: 'equals', label: 'equals' },
    { value: 'greater_than_or_equal_to', label: 'greater than or equal to' },
    { value: 'less_than_or_equal_to', label: 'less than or equal to' },
];

import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
    operator: z.string().min(1, "Please select an operator"),
    amount: z.string().min(1, "Please enter an amount"),
    applyToSum: z.boolean().default(false),
    applyToLineItem: z.boolean().default(false),
}).refine(
    (data) => data.applyToSum || data.applyToLineItem,
    {
        message: "At least one alert type must be selected",
        path: ["applyToLineItem"], // This will show the error message under the last checkbox
    }
);

type FormValues = z.infer<typeof formSchema>;

const ChargeTypeAlertForm = ({
    chargeType,
    onClose,
    onSubmit,
}: {
    chargeType: ChargeType;
    onClose: () => void;
    onSubmit: (data: FormValues) => void;
}) => {
    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            operator: '',
            amount: '',
            applyToSum: false,
            applyToLineItem: false,
        },
    });

    const handleSubmit = async (data: FormValues) => {
        const config = {
            operator: data.operator,
            amount: parseFloat(data.amount),
            apply_to_sum: data.applyToSum,
            apply_to_line_item: data.applyToLineItem 
        }
        try {
            const alertData: PostConfigAlertsPostData = {
                body: {
                  alert_type: 'app',
                  alert_resource: 'charge_type',
                  resource_uuid: chargeType.id,
                  alert_config: config
                }
            };
            
            const response = await postConfigAlertsPost(alertData);
            if (response.error) {
                console.error(response.error)
            }
            onSubmit(data);
        } catch (error) {
            console.error('Failed to create alert:', error);
        }
    };

    return (
    <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <div>
                <div className="flex font-bold">Alert Condition</div>
                <FormField
                    control={form.control}
                    name="operator"
                    render={({ field: { onChange, value, ...field } }) => (
                        <FormItem>
                            <FormControl>
                                <Select
                                    {...field}
                                    value={value ? { value, label: value } : null}
                                    onChange={(option) => onChange(option?.value)}
                                    options={operators}
                                    placeholder="Select operator"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div>
                <div className="flex font-bold">Amount</div>
                <FormField
                    control={form.control}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    {...field}
                                    type="number"
                                    step="0.01"
                                    placeholder="Enter dollar amount"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div>
                <div className="flex font-bold mb-2">Alert Type</div>
                <div className="space-y-2">
                    <FormField
                        control={form.control}
                        name="applyToSum"
                        render={({ field }) => (
                            <FormItem>
                                <div className="flex flex-row items-center space-x-2">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                        Apply for sum of all line items
                                    </FormLabel>
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="applyToLineItem"
                        render={({ field }) => (
                            <FormItem>
                                <div className="flex flex-row items-center space-x-2">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                        Apply for matching line item
                                    </FormLabel>
                                </div>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
            <div className="flex justify-end gap-2 pt-4">
                <Button type="button" variant="outline" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit">
                    Save Alert
                </Button>
            </div>
        </form>
    </Form>
  )};

export default ChargeTypeAlertForm;
