import {
    type BrandUser,
    readBrandBrandIdUsersGet,
    type InvoiceCustomerPublic,
    updateBrandBrandIdPatch,
    updateBrandBrandIdUsersUserIdPatch,
    deleteBrandBrandIdUsersUserIdDelete,
} from '@/client';
import { useEffect, useState } from 'react';
import { Switch } from '../../ui/switch';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui/button';
import CreateBrandUser from './CreateBrandUser';
import BrandUsersTable from './BrandUsersTable';

export interface BrandAdminProps {
    invoiceCustomer: InvoiceCustomerPublic;
}

const BrandAdmin = ({ invoiceCustomer }: BrandAdminProps) => {
    const [isEnabled, setIsEnabled] = useState<boolean>(
        invoiceCustomer.portal_enabled ?? false,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
    const [brandUsers, setBrandUsers] = useState<BrandUser[]>([]);

    const navigate = useNavigate();

    const fetchBrandUsers = async () => {
        setIsLoading(true);
        try {
            const response = await readBrandBrandIdUsersGet({
                throwOnError: true,
                path: { brand_id: invoiceCustomer.id! },
            });
            setBrandUsers(response.data);
        } catch (error) {
            throw new Error('Error fetching brand users');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBrandPortalToggle = async () => {
        try {
            await updateBrandBrandIdPatch({
                throwOnError: true,
                path: { brand_id: invoiceCustomer.id! },
                body: { portal_enabled: !isEnabled },
            });
            invoiceCustomer.portal_enabled = !isEnabled;
            setIsEnabled(!isEnabled);
        } catch (error) {
            throw new Error('Error updating brand portal');
        }
    };

    const handleBrandUserToggle = async (user: BrandUser) => {
        try {
            await updateBrandBrandIdUsersUserIdPatch({
                throwOnError: true,
                path: { brand_id: invoiceCustomer.id!, user_id: user.user_id },
                body: { is_active: !user.is_active },
            });
            setBrandUsers(
                brandUsers.map((u) =>
                    u.user_id === user.user_id
                        ? { ...u, is_active: !user.is_active }
                        : u,
                ),
            );
        } catch (error) {
            throw new Error('Error updating brand user');
        }
    };

    const handleDeleteUser = async (user: BrandUser) => {
        try {
            await deleteBrandBrandIdUsersUserIdDelete({
                throwOnError: true,
                path: { brand_id: invoiceCustomer.id!, user_id: user.user_id },
            });
            setBrandUsers(brandUsers.filter((u) => u.user_id !== user.user_id));
        } catch (error) {
            throw new Error('Error deleting brand user');
        }
    };

    useEffect(() => {
        fetchBrandUsers();
    }, []);

    return (
        <div className="p-1">
            <h1 className="font-medium">Enable Brand Portal</h1>
            <div className="flex items-center gap-4 mt-2">
                <Switch
                    checked={isEnabled}
                    onCheckedChange={handleBrandPortalToggle}
                />
                <div className="flex items-center ">
                    <div className="h-4 w-px bg-gray-200" />
                    <Button
                        variant="link"
                        onClick={() => setCreateUserDialogOpen(true)}
                        disabled={!isEnabled}
                    >
                        Add User
                    </Button>
                </div>
            </div>
            <div className="gap-4 mt-2">
                <BrandUsersTable
                    users={brandUsers}
                    isEnabled={isEnabled}
                    isLoading={isLoading}
                    onToggleUser={handleBrandUserToggle}
                    onDeleteUser={handleDeleteUser}
                />
            </div>
            <div>
                <CreateBrandUser
                    invoiceCustomer={invoiceCustomer}
                    dialogOpen={createUserDialogOpen}
                    setDialogOpen={setCreateUserDialogOpen}
                    onCreate={() => {
                        fetchBrandUsers();
                    }}
                />
            </div>
        </div>
    );
};

export default BrandAdmin;
