import React, { useState, useEffect } from 'react';
import { Plus, ChevronDown, ChevronUp, Pencil, Eye } from 'lucide-react';
import { ArrowDownTrayIcon, PaperAirplaneIcon, ArchiveBoxXMarkIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import {
    getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet,
    getInvoiceChargeTypesGet,
    postInvoiceLineitemsPost,
    updateLineItemAmountManuallyInvoiceLineitemsInvoiceIdLineItemIdPut,
    postInvoiceInvoicesQuickbooksPost,
    getInvoiceInvoicesDeliveryFileInvoiceIdGet,
    putInvoiceInvoicesPut,
    readManyQbInvoicesGet,
    getInvoiceStatusQbInvoiceStatusPost,
    getSingleInvoiceSummaryInvoiceInvoiceSummariesInvoiceIdGet
} from '@/client/services.gen';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogClose, DialogFooter } from '@/components/ui/dialog';
import { Input } from '@/components/common/input';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/common/form';
import Select from 'react-select';
import LoadingSpinner from '@/components/common/loadingSpinner';
import InvoiceAudits from './invoiceAudits';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel } from '@/components/ui/alert-dialog';
import OrderDetailsDialog from './orderDetails';

interface ChargeType { label: string; value: string; charge_level: string; }
interface InvoiceCharge { line_item_id: string; charge_on_invoice: string | null; charge_type_name: string; quantity: number; amount: number; }
interface NonInvoiceLevelData { order_id: string; package_id: string; order_number: string; charges: InvoiceCharge[]; }
interface StorageCharge {
    storage_type: string; total_quantity: number; total_amount: string;
    details: { line_item_id: string; location: string; quantity: number; amount: string; }[];
}
interface InvoiceData {
    customer_name: string; billing_period_start: string; billing_period_end: string;
    voided_at: string | null; status: string; total_invoice_amount: number; quickbooks_balance?: number;
    return_charges?: ReturnData[];
}

interface ReturnData {
    charges: InvoiceCharge[];
    order_number: string;
    return_datetime: string;
    return_id: string;
}

const invoiceChargeSchema = z.object({
    charge_type_id: z.object({ label: z.string(), value: z.string() }),
    amount: z.string().min(1, { message: 'Required' }),
    total_quantity: z.string().min(1, { message: 'Required' }),
    charge_on_invoice: z.string().min(1, { message: 'Required' }),
    order_id: z.string().optional(),
    package_id: z.string().optional(),
});

const editInvoiceChargeSchema = z.object({
    charge_type_name: z.string().optional(),
    old_amount: z.string(),
    amount: z.string().optional(),
    quantity: z.number().optional(),
    description: z.string().optional(),
});

type InvoiceChargeSchema = z.infer<typeof invoiceChargeSchema>;
type EditInvoiceChargeSchema = z.infer<typeof editInvoiceChargeSchema>;

interface CollapsibleSectionProps {
    title: string;
    isOpen: boolean;
    onToggle: () => void;
    children: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
    title,
    isOpen,
    onToggle,
    children
}) => (
    <div className="mt-12">
        <Button
            variant="ghost"
            className="w-full flex justify-between items-center p-4 bg-white rounded-lg border border-gray-200 shadow-sm"
            onClick={onToggle}
        >
            <span className="text-lg font-semibold text-rails-dark-blue">{title}</span>
            {isOpen ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
        </Button>
        {isOpen && (
            <div className="mt-4">
                {children}
            </div>
        )}
    </div>
);

const SingleInvoiceDetail: React.FC = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nonInvoiceLevelData, setNonInvoiceLevelData] = useState<NonInvoiceLevelData[]>([]);
    const [invoiceLevelCharges, setInvoiceLevelCharges] = useState<InvoiceCharge[]>([]);
    const [selectedCharge, setSelectedCharge] = useState<InvoiceCharge | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<NonInvoiceLevelData | null>(null);
    const [isEditChargeOpen, setIsEditChargeOpen] = useState<boolean>(false);
    const [invoiceChargeTypes, setInvoiceChargeTypes] = useState<ChargeType[]>([]);
    const [storageCharges, setStorageCharges] = useState<StorageCharge[]>([]);
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
    const [loading, setLoading] = useState(false);
    const [loadingQuickBooks, setLoadingQuickBooks] = useState(false);
    const [successUpload, setSuccessUpload] = useState(false);
    const [openInvoiceLevelCharges, setOpenInvoiceLevelCharges] = useState(false);
    const [openStorageCharges, setOpenStorageCharges] = useState(false);
    const [openOrderCharges, setOpenOrderCharges] = useState(false);
    const [openCarrierCharges, setOpenCarrierCharges] = useState(false);
    const [carrierCharges, setCarrierCharges] = useState<any[]>([]);
    const [openAudits, setOpenAudits] = useState(false);
    const [syncingQuickBooks, setSyncingQuickBooks] = useState(false);
    const [addChargeDialog, setAddChargeDialog] = useState<{
        isOpen: boolean;
        isInvoiceLevel: boolean;
    }>({
        isOpen: false,
        isInvoiceLevel: false
    });
    const formProps = useForm<InvoiceChargeSchema>({
        resolver: zodResolver(invoiceChargeSchema),
        defaultValues: {
            charge_type_id: { label: '', value: '' },
            amount: '',
            total_quantity: '',
            charge_on_invoice: '',
            order_id: '',
            package_id: '',
        },
    });

    const editFormProps = useForm<EditInvoiceChargeSchema>({
        resolver: zodResolver(editInvoiceChargeSchema),
        defaultValues: {
            charge_type_name: selectedCharge?.charge_on_invoice || '',
            old_amount: selectedCharge?.amount?.toString() || '0',
            amount: selectedCharge?.amount?.toString() || '0',
            quantity: selectedCharge?.quantity || 0,
            description: selectedCharge?.charge_on_invoice || '',
        },
    });

    const { handleSubmit, reset, control } = formProps;
    const { handleSubmit: handleEditSubmit, reset: resetEdit, control: controlEdit } = editFormProps;

    const invoiceLevelChargeColumns = [
        {
            accessorKey: 'charge_on_invoice',
            header: 'Charge Name',
            cell: ({ row }: { row: any }) => (
                <span>{row.original.charge_on_invoice}</span>
            ),
            enableColumnFilter: true,
            filterType: 'text',
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'quantity',
            header: 'Quantity',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: 'Charge',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="flex items-center gap-2">
                    <NumericFormat 
                        value={row.getValue('amount')} 
                        displayType="text" 
                        thousandSeparator="," 
                        prefix="$" 
                        decimalScale={2} 
                        fixedDecimalScale 
                    />
                    <Pencil 
                        className="h-4 w-4 cursor-pointer text-gray-500 hover:"
                        onClick={() => {
                            setSelectedCharge(row.original);
                            resetEdit({
                                charge_type_name: row.original.charge_on_invoice,
                                old_amount: row.original.amount.toString(),
                                amount: row.original.amount.toString(),
                                quantity: row.original.quantity,
                                description: row.original.description || '',
                            });
                            setIsEditChargeOpen(true);
                        }}
                    />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'description',
            header: 'Description',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.description}</div>
            ),
            enableColumnFilter: true,
            filterType: 'text',
            enableSorting: true,
        }
    ];

    const carrierChargeColumns = [
        {
            accessorKey: 'order_number',
            header: 'Order Number',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.order_number}</div>
            ),
        },
        {
            accessorKey: 'tracking_number',
            header: 'Tracking Number',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.tracking_number}</div>
            ),
        },
        {
            accessorKey: 'carrier_name',
            header: 'Carrier',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">{row.original.carrier_name}</div>
            ),
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <NumericFormat 
                        value={row.original.amount} 
                        displayType="text" 
                        thousandSeparator="," 
                        prefix="$" 
                        decimalScale={2} 
                    />
                </div>
            ),
        },

    ];

    const storageChargeColumns = [
        {
            accessorKey: 'storage_type',
            header: 'Storage Type',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium">
                    {row.original.storage_type}
                </div>
            ),
        },
        {
            accessorKey: 'total_quantity',
            header: 'Total Quantity',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <NumericFormat 
                        value={row.original.total_quantity} 
                        displayType="text" 
                        thousandSeparator="," 
                        decimalScale={0} 
                    />
                </div>
            ),
        },
        {
            accessorKey: 'total_amount',
            header: 'Total Amount',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <NumericFormat 
                        value={row.original.total_amount} 
                        displayType="text" 
                        thousandSeparator="," 
                        prefix="$" 
                        decimalScale={2} 
                    />
                </div>
            ),
        },
    ];

    const [transformedOrderData, setTransformedOrderData] = useState<any[]>([]);
    const [orderColumns, setOrderColumns] = useState<any[]>([]);
    const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);

    useEffect(() => {
        if (nonInvoiceLevelData.length > 0) {
            const uniqueChargeTypes = Array.from(new Set(
                nonInvoiceLevelData.flatMap(order => 
                    order.charges.map(charge => charge.charge_on_invoice)
                )
            )).sort();

            const columns = [
                {
                    id: 'order_number',
                    accessorKey: 'order_number',
                    header: 'Order Number',
                    enableColumnFilter: true,
                    filterType: 'text'
                },
                {
                    id: 'tracking_number',
                    accessorKey: 'tracking_number',
                    header: 'Tracking Number',
                    enableColumnFilter: true,
                    filterType: 'text'
                },
                ...uniqueChargeTypes.map(chargeType => ({
                    id: chargeType,
                    accessorKey: chargeType,
                    header: chargeType,
                    enableColumnFilter: true,
                    filterType: 'number',
                    cell: ({ row }) => {
                      const charge = nonInvoiceLevelData
                                      .find(order => order.order_number === row.original.order_number)
                                      ?.charges.find(c => c.charge_on_invoice === chargeType);

                      if (charge) {
                        return (
                          <div className="flex items-center gap-2">
                              <NumericFormat 
                                  value={row.getValue(chargeType) || 0}
                                  displayType="text"
                                  thousandSeparator=","
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale
                              />
                              <Pencil 
                                  className="h-4 w-4 cursor-pointer text-gray-500 hover:"
                                  onClick={() => {
                                      setSelectedCharge(charge);
                                      resetEdit({
                                          charge_type_name: charge.charge_on_invoice || undefined,
                                          old_amount: charge.amount.toString(),
                                          amount: charge.amount.toString(),
                                          quantity: charge.quantity,
                                          description: charge.charge_on_invoice || '',
                                      });
                                      setIsEditChargeOpen(true);
                                  }}
                              />
                          </div>
                        )
                      } else {
                          return (
                            <div className="flex items-center gap-2">
                              --
                            </div>
                          )
                      }
                    }
                })),
                {
                    id: 'details',
                    header: '',
                    cell: ({ row }) => {
                        const order = nonInvoiceLevelData.find(o => o.order_number === row.original.order_number);
                        return (
                            <div className="flex space-x-2">
                                <Button 
                                    variant="ghost" 
                                    size="icon"
                                    className="h-8 w-8"
                                    onClick={() => {
                                        setSelectedOrderId(order?.order_id || null);
                                        setIsOrderDetailsOpen(true);
                                    }}
                                >
                                    <Eye className="h-4 w-4" />
                                </Button>
                                <Button 
                                    variant="ghost" 
                                    size="icon"
                                    className="h-8 w-8"
                                    onClick={() => {
                                        setSelectedOrder(order || null);
                                        setAddChargeDialog({ isOpen: true, isInvoiceLevel: false });
                                    }}
                                >
                                    <Plus className="h-4 w-4" />
                                </Button>
                            </div>
                        );
                    }
                }
            ];

            const data = nonInvoiceLevelData.map(order => {
                const row: Record<string, any> = {
                    order_number: order.order_number,
                    tracking_number: order.tracking_number
                };

                uniqueChargeTypes.forEach(chargeType => {
                    row[chargeType] = 0;
                });

                order.charges.forEach(charge => {
                    if (charge.charge_on_invoice) {
                        row[charge.charge_on_invoice] = (row[charge.charge_on_invoice] || 0) + 
                            parseFloat(charge.amount.toString());
                    }
                });

                return row;
            });

            setTransformedOrderData(data);
            setOrderColumns(columns);
        }
    }, [nonInvoiceLevelData]);

    const fetchChargeTypes = async () => {
        const { data, error } = await getInvoiceChargeTypesGet();
        if (error) {
            console.error('Error fetching charge types:', error);
            return;
        }

        const filteredData = data!
            .filter((item: any) => !item.name.toLowerCase().includes('special rule'))
            .map((item: any) => ({ label: item.name, value: item.id, charge_level: item.charge_level }));
        setInvoiceChargeTypes(filteredData);
    };

    const fetchInvoiceDetails = async () => {
        try {
            const [invoiceResponse, invoiceSummaryResponse, qbInvoiceResponse] = await Promise.all([
                getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet({ path: { invoiceId: invoiceId! } }),
                getSingleInvoiceSummaryInvoiceInvoiceSummariesInvoiceIdGet({ path: { invoiceId: invoiceId! } }),
                readManyQbInvoicesGet()
            ]);

            const output = invoiceResponse.data!;
            
            setNonInvoiceLevelData((output.package_charges || []) as NonInvoiceLevelData[]);
            setCarrierCharges(output.carrier_charges || []);

            const summaryData = invoiceSummaryResponse.data[0];
            
            const qbBalance = qbInvoiceResponse.data?.length > 0 
                ? qbInvoiceResponse.data.find(qbInv => qbInv.invoice_id === invoiceId)?.quickbooks_balance 
                : undefined;

            const invoiceDataToSet = {
                customer_name: summaryData.customer_name,
                billing_period_start: summaryData.billing_period_start,
                billing_period_end: summaryData.billing_period_end,
                voided_at: summaryData.voided_at,
                status: summaryData.status,
                total_invoice_amount: summaryData.total_invoice_amount,
                quickbooks_balance: qbBalance,
                return_charges: output.return_charges
            };

            setInvoiceData(invoiceDataToSet);

            const storageChargesByType = (output.storage_charges || []).reduce((acc: any, charge: any) => {
                const key = charge.storage_type;
                if (!acc[key]) {
                    acc[key] = {
                        storage_type: key,
                        total_quantity: 0,
                        total_amount: '0',
                        details: []
                    };
                }
                acc[key].total_quantity += charge.quantity;
                acc[key].total_amount = (parseFloat(acc[key].total_amount) + parseFloat(charge.amount)).toString();
                acc[key].details.push(charge);
                return acc;
            }, {});

            setStorageCharges(Object.values(storageChargesByType) as StorageCharge[]);
            setInvoiceLevelCharges(output.invoice_level_charges);

        } catch (error) {
            console.error('Failed to fetch invoice details:', error);
            return;
        }
    };

    useEffect(() => {
        setIsLoading(true);

        const loadData = async () => {
            
            if (invoiceId) {
                try {
                    await fetchChargeTypes();
                    await fetchInvoiceDetails();
                } catch (error) {
                    console.error('Error in loadData:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadData();
    }, [invoiceId]);

    const onSubmit = async (values: InvoiceChargeSchema, isInvoiceLevel: boolean) => {
        setIsLoading(true);
        try {
            const selectedChargeType = invoiceChargeTypes.find((type) => type.value === values.charge_type_id.value);

            const payload: any = {
                amount: parseFloat(values.amount),
                quantity: parseFloat(values.total_quantity),
                charge_type_id: values.charge_type_id.value,
                invoice_id: invoiceId,
                description: values.charge_on_invoice,
            };

            // Only add order_id, package_id, or return_id for non-invoice-level charges
            if (!isInvoiceLevel && selectedOrder) {
                if (selectedChargeType?.charge_level === 'package_level') {
                    payload.package_id = selectedOrder.package_id;
                    payload.order_id = selectedOrder.order_id;
                } else if (selectedChargeType?.charge_level === 'order_level') {
                    payload.order_id = selectedOrder.order_id;
                } else if (selectedChargeType?.charge_level === 'return_order_level') {
                    payload.return_id = selectedOrder.return_id;
                    payload.order_id = selectedOrder.order_id;
                }
            }

            await postInvoiceLineitemsPost({ body: payload });
            await fetchInvoiceDetails();
            reset();
            setAddChargeDialog({ isOpen: false, isInvoiceLevel });
        } catch (error) {
            console.error('Error adding charge:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onEditSubmit = async (values: EditInvoiceChargeSchema) => {
        setIsLoading(true);
        try {
            if (invoiceId && selectedCharge) {
                const updatePayload: {
                    amount: number;
                    quantity?: number;
                    description?: string;
                } = {
                    amount: parseFloat(values.amount),
                };

                // Add quantity for all charges
                if (typeof values.quantity === 'number') {
                    updatePayload.quantity = values.quantity;
                }

                // Only add description for invoice level charges
                const isInvoiceLevelCharge = invoiceLevelCharges.some(
                    charge => charge.line_item_id === selectedCharge.line_item_id
                );
                if (isInvoiceLevelCharge && values.description) {
                    updatePayload.description = values.description;
                }

                await updateLineItemAmountManuallyInvoiceLineitemsInvoiceIdLineItemIdPut({
                    path: {
                        invoiceId: invoiceId,
                        lineItemId: selectedCharge.line_item_id,
                    },
                    query: updatePayload,
                });
                await fetchInvoiceDetails();
                setIsEditChargeOpen(false);
            }
        } catch (error) {
            console.error('Error updating charge:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (addChargeDialog.isOpen) {
            reset({
                charge_type_id: { label: '', value: '' },
                amount: '',
                total_quantity: '',
                charge_on_invoice: '',
                order_id: selectedOrder?.order_id || '',
                package_id: selectedOrder?.package_id || '',
            });
        }
    }, [addChargeDialog.isOpen, reset, selectedOrder]);

    const handleDownload = async () => {
        setLoading(true);
        try {
            const res = await getInvoiceInvoicesDeliveryFileInvoiceIdGet({ path: { invoice_id: invoiceId! } });
            const tempLink = document.createElement('a');
            tempLink.href = res.data!.file_url;
            tempLink.setAttribute('download', `shippy_${invoiceId}_invoice_report.csv`);
            tempLink.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUploadToQuickBooks = async () => {
        setLoadingQuickBooks(true);
        try {
            const response = await getInvoiceInvoicesDeliveryFileInvoiceIdGet({ path: { invoice_id: invoiceId! } });
            const invoiceDetail = response.data!;
            if (invoiceDetail && invoiceDetail.file_url) {
                await postInvoiceInvoicesQuickbooksPost({ 
                    body: { invoice_id: invoiceId!, file_url: invoiceDetail.file_url } 
                });
                setSuccessUpload(true);
            }
        } catch (error) {
            console.error('Error uploading to QuickBooks:', error);
        } finally {
            setLoadingQuickBooks(false);
        }
    };

    const handleVoid = async () => {
        try {
            await putInvoiceInvoicesPut({ 
                query: { invoiceId: invoiceId!, void: true } 
            });
            await fetchInvoiceDetails();
        } catch (error) {
            console.error('Error voiding invoice: ', error);
        }
    };

    const handleQuickBooksSync = async () => {
        setSyncingQuickBooks(true);
        try {
            await getInvoiceStatusQbInvoiceStatusPost();
            await fetchInvoiceDetails();
        } catch (error) {
            console.error('Error syncing with QuickBooks:', error);
        } finally {
            setSyncingQuickBooks(false);
        }
    };

    const getFilteredChargeTypes = (isInvoiceLevel: boolean) => {
        if (isInvoiceLevel) {
            return invoiceChargeTypes.filter(type => type.charge_level === 'invoice_level');
        } else {
            const isReturnOrder = selectedOrder && invoiceData?.return_charges?.some(
                returnOrder => returnOrder.return_id === selectedOrder.return_id
            );

            if (isReturnOrder) {
                return invoiceChargeTypes.filter(type => type.charge_level === 'return_order_level');
            } else if (!selectedOrder?.package_id) {
                return invoiceChargeTypes.filter(type => type.charge_level === 'order_level');
            } else {
                return invoiceChargeTypes.filter(type => 
                    type.charge_level === 'order_level' || type.charge_level === 'package_level'
                );
            }
        }
    };

    const [openReturnCharges, setOpenReturnCharges] = useState(false);
    const [transformedReturnData, setTransformedReturnData] = useState<any[]>([]);
    const [returnColumns, setReturnColumns] = useState<any[]>([]);

    useEffect(() => {
        if (invoiceData?.return_charges?.length > 0) {
            const uniqueChargeTypes = Array.from(new Set(
                invoiceData.return_charges.flatMap(returnOrder => 
                    returnOrder.charges.map(charge => charge.charge_on_invoice)
                )
            )).sort();

            const columns = [
                {
                    id: 'order_number',
                    accessorKey: 'order_number',
                    header: 'Order Number',
                    enableColumnFilter: true,
                    filterType: 'text'
                },
                {
                    id: 'return_datetime',
                    accessorKey: 'return_datetime',
                    header: 'Return Date',
                    enableColumnFilter: true,
                    filterType: 'text',
                    cell: ({ row }) => (
                        <span>
                            {new Date(row.getValue('return_datetime')).toLocaleDateString()}
                        </span>
                    )
                },
                ...uniqueChargeTypes.map(chargeType => ({
                    id: chargeType,
                    accessorKey: chargeType,
                    header: chargeType,
                    enableColumnFilter: true,
                    filterType: 'number',
                    cell: ({ row }) => (
                        <div className="flex items-center gap-2">
                            <NumericFormat 
                                value={row.getValue(chargeType) || 0}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                            <Pencil 
                                className="h-4 w-4 cursor-pointer text-gray-500 hover:"
                                onClick={() => {
                                    const charge = invoiceData.return_charges
                                        .find(order => order.order_number === row.original.order_number)
                                        ?.charges.find(c => c.charge_on_invoice === chargeType);
                                    
                                    if (charge) {
                                        setSelectedCharge(charge);
                                        resetEdit({
                                            charge_type_name: charge.charge_on_invoice || undefined,
                                            old_amount: charge.amount.toString(),
                                            amount: charge.amount.toString(),
                                            quantity: charge.quantity,
                                            description: charge.charge_on_invoice || '',
                                        });
                                        setIsEditChargeOpen(true);
                                    }
                                }}
                            />
                        </div>
                    )
                })),
                {
                    id: 'actions',
                    header: '',
                    cell: ({ row }) => {
                        const returnOrder = invoiceData.return_charges.find(o => o.order_number === row.original.order_number);
                        return (
                            <Button 
                                variant="ghost" 
                                size="icon"
                                className="h-8 w-8"
                                onClick={() => {
                                    setSelectedOrder(returnOrder || null);
                                    setAddChargeDialog({ isOpen: true, isInvoiceLevel: false });
                                }}
                            >
                                <Plus className="h-4 w-4" />
                            </Button>
                        );
                    }
                }
            ];

            const data = invoiceData.return_charges.map(returnOrder => {
                const row: Record<string, any> = {
                    order_number: returnOrder.order_number,
                    return_datetime: returnOrder.return_datetime,
                };

                uniqueChargeTypes.forEach(chargeType => {
                    row[chargeType] = 0;
                });

                returnOrder.charges.forEach(charge => {
                    if (charge.charge_on_invoice) {
                        row[charge.charge_on_invoice] = (row[charge.charge_on_invoice] || 0) + 
                            parseFloat(charge.amount.toString());
                    }
                });
                
                return row;
            });

            setTransformedReturnData(data);
            setReturnColumns(columns);
        }
    }, [invoiceData?.return_charges]);

    if (isLoading || !invoiceData) {
        return (
            <div className="h-screen flex items-center justify-center">
                <LoadingSpinner loading={true} />
            </div>
        );
    }

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none px-4 sm:px-6 md:px-8 py-6">
                <h1 className="text-2xl font-bold text-rails-dark-blue">Invoice Details</h1>

                <div className="mt-12 bg-white rounded-lg border border-gray-200 shadow-sm">
                    <div className="p-4">
                        <h2 className="text-xl font-semibold text-rails-dark-blue">
                            Invoice #{invoiceId}
                        </h2>
                        {invoiceData && (
                            <>
                                <div className="text-base mt-2">
                                    <span className="font-medium">Customer:</span> {invoiceData.customer_name}
                                </div>
                                <div className="text-base">
                                    <span className="font-medium">Billing Period:</span>{' '}
                                    {new Date(invoiceData.billing_period_start).toLocaleDateString()} - {new Date(invoiceData.billing_period_end).toLocaleDateString()}
                                </div>
                                <div className="text-base">
                                    <span className="font-medium">Status:</span>{' '}
                                    {invoiceData.status}
                                </div>
                                <div className="text-base">
                                    <span className="font-medium">Total Invoice Amount:</span>{' '}
                                    <NumericFormat 
                                        value={invoiceData.total_invoice_amount} 
                                        displayType="text" 
                                        thousandSeparator="," 
                                        prefix="$" 
                                        decimalScale={2} 
                                        fixedDecimalScale
                                    />
                                </div>
                                <div className="text-base">
                                    <span className="font-medium">QuickBooks Customer Balance:</span>{' '}
                                    <NumericFormat 
                                        value={invoiceData.quickbooks_balance} 
                                        displayType="text" 
                                        thousandSeparator="," 
                                        prefix="$" 
                                        decimalScale={2} 
                                        fixedDecimalScale
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="border-t border-gray-200 p-4 flex justify-end space-x-4">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button disabled={invoiceData?.voided_at !== null}>
                                    <ArchiveBoxXMarkIcon className="h-4 w-4 mr-2" />
                                    {invoiceData?.voided_at === null ? 'Void' : 'Voided'}
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-md">
                                <DialogHeader>
                                    <DialogTitle>Confirm</DialogTitle>
                                    <DialogDescription>{`Are you sure you want to void invoice ${invoiceId}?`}</DialogDescription>
                                </DialogHeader>
                                <DialogFooter>
                                    <DialogClose asChild>
                                        <Button onClick={handleVoid}>Confirm</Button>
                                    </DialogClose>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>

                        <Button 
                            className={loading ? 'animate-pulse' : ''} 
                            onClick={handleDownload}
                        >
                            <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
                            {loading ? 'Downloading' : 'Download'}
                        </Button>

                        <Button 
                            className={loadingQuickBooks ? 'animate-pulse' : ''} 
                            onClick={handleUploadToQuickBooks}
                            disabled={invoiceData?.voided_at !== null}
                        >
                            <PaperAirplaneIcon className="h-4 w-4 mr-2" />
                            {loadingQuickBooks ? 'Exporting' : 'Export'}
                        </Button>

                        <Button 
                            className={syncingQuickBooks ? 'animate-pulse' : ''} 
                            onClick={handleQuickBooksSync}
                            disabled={invoiceData?.voided_at !== null}
                        >
                            <ArrowPathIcon className="h-4 w-4 mr-2" />
                            {syncingQuickBooks ? 'Syncing' : 'Sync QuickBooks'}
                        </Button>
                    </div>
                </div>

                <CollapsibleSection
                    title="Invoice Reconciliation"
                    isOpen={openAudits}
                    onToggle={() => setOpenAudits(!openAudits)}
                >
                    <InvoiceAudits invoiceId={invoiceId as string} />
                </CollapsibleSection>

                <CollapsibleSection
                    title="Invoice Level Charges"
                    isOpen={openInvoiceLevelCharges}
                    onToggle={() => setOpenInvoiceLevelCharges(!openInvoiceLevelCharges)}
                >
                    <DataTable
                        loading={isLoading}
                        data={invoiceLevelCharges}
                        columns={invoiceLevelChargeColumns}
                        ActionButton={
                            <Button className="p-4" onClick={() => setAddChargeDialog({ isOpen: true, isInvoiceLevel: true })}>
                                <Plus className="h-4 w-4 mr-2" />
                                Add Charge
                            </Button>
                        }
                        showActionButtonInToolbar
                        showActionButtonInCollapsible
                        isMultiSelectRows={false}
                        tableName="invoice-level-charges"
                    />
                </CollapsibleSection>

                <CollapsibleSection
                    title="Storage Charges"
                    isOpen={openStorageCharges}
                    onToggle={() => setOpenStorageCharges(!openStorageCharges)}
                >
                    <DataTable
                        loading={isLoading}
                        data={storageCharges}
                        columns={storageChargeColumns}
                        showActionButtonInToolbar={false}
                        isMultiSelectRows={false}
                        tableName="storage-charges"
                    />
                </CollapsibleSection>

                <CollapsibleSection
                    title="Order and Package Charges"
                    isOpen={openOrderCharges}
                    onToggle={() => setOpenOrderCharges(!openOrderCharges)}
                >
                    <DataTable
                        loading={isLoading}
                        data={transformedOrderData}
                        columns={orderColumns}
                        showActionButtonInToolbar={false}
                        showActionButtonInCollapsible={false}
                        isMultiSelectRows={false}
                        tableName="order-charges"
                    />
                </CollapsibleSection>

                {transformedReturnData.length > 0 && (
                    <CollapsibleSection
                        title="Return Charges"
                        isOpen={openReturnCharges}
                        onToggle={() => setOpenReturnCharges(!openReturnCharges)}
                    >
                        <DataTable
                            loading={isLoading}
                            data={transformedReturnData}
                            columns={returnColumns}
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible={false}
                            isMultiSelectRows={false}
                            tableName="return-charges"
                        />
                    </CollapsibleSection>
                )}

                {carrierCharges?.length > 0 && (
                    <CollapsibleSection
                        title="Shipping Charge Adjustments"
                        isOpen={openCarrierCharges}
                        onToggle={() => setOpenCarrierCharges(!openCarrierCharges)}
                    >
                        <DataTable
                            loading={isLoading}
                            data={carrierCharges}
                            columns={carrierChargeColumns}
                            showActionButtonInToolbar={false}
                            showActionButtonInCollapsible={false}
                            isMultiSelectRows={false}
                            tableName="carrier-charges"
                        />
                    </CollapsibleSection>
                )}

                <AlertDialog open={successUpload} onOpenChange={setSuccessUpload}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Invoice Upload to QuickBooks Successful!</AlertDialogTitle>
                            <AlertDialogDescription>
                                Your invoice has been uploaded successfully. Click Done to continue
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel onClick={() => setSuccessUpload(false)}>
                                Done
                            </AlertDialogCancel>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>

                <Dialog open={isEditChargeOpen} onOpenChange={setIsEditChargeOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Edit Charge</DialogTitle>
                            <DialogDescription>
                                Update the charge details for this line item.
                            </DialogDescription>
                        </DialogHeader>
                        <Form {...editFormProps}>
                            <form onSubmit={handleEditSubmit(onEditSubmit)} className="space-y-4">
                                <FormField
                                    control={controlEdit}
                                    name="amount"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Amount</FormLabel>
                                            <FormControl>
                                                <Input
                                                    type="number"
                                                    step="0.01"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={controlEdit}
                                    name="quantity"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Quantity</FormLabel>
                                            <FormControl>
                                                <Input
                                                    type="number"
                                                    {...field}
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {invoiceLevelCharges.some(charge => charge.line_item_id === selectedCharge?.line_item_id) && (
                                    <FormField
                                        control={controlEdit}
                                        name="description"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Description</FormLabel>
                                                <FormControl>
                                                    <Input {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                )}
                                <Button type="submit">Save Changes</Button>
                            </form>
                        </Form>
                    </DialogContent>
                </Dialog>

                <Dialog 
                    open={addChargeDialog.isOpen} 
                    onOpenChange={(isOpen) => setAddChargeDialog(prev => ({ ...prev, isOpen }))}
                >
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Add Charge</DialogTitle>
                            <DialogDescription>
                                Add a new charge to this invoice.
                            </DialogDescription>
                        </DialogHeader>
                        <Form {...formProps}>
                            <form onSubmit={handleSubmit((data) => onSubmit(data, addChargeDialog.isInvoiceLevel))} className="space-y-4">
                                <FormField
                                    control={control}
                                    name="charge_type_id"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Charge Type</FormLabel>
                                            <FormControl>
                                                <Select
                                                    {...field}
                                                    options={getFilteredChargeTypes(addChargeDialog.isInvoiceLevel)}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="amount"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Amount</FormLabel>
                                            <FormControl>
                                                <Input type="number" step="0.01" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="total_quantity"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Quantity</FormLabel>
                                            <FormControl>
                                                <Input type="number" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="charge_on_invoice"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Description</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button type="submit">Add Charge</Button>
                            </form>
                        </Form>
                    </DialogContent>
                </Dialog>

                <OrderDetailsDialog
                    orderId={selectedOrderId}
                    isOpen={isOrderDetailsOpen}
                    onClose={() => setIsOrderDetailsOpen(false)}
                    charges={nonInvoiceLevelData.find(order => order.order_id === selectedOrderId)?.charges}
                />
            </main>
        </div>
    );
};

export default SingleInvoiceDetail;
