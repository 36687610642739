export const transformPricingCategories = (
    pricingCategories: any[],
    ruleData: any[],
) => {
    // Handle case where pricingCategories is not an array
    if (!Array.isArray(pricingCategories)) {
        return [];
    }

    // Iterate over each pricing category
    return pricingCategories.map((category) => {
        const matchedRules = ruleData.filter(
            (rule) => rule.charge_type_id === category.id,
        );

        // Create a new category object with the transformed rules
        return {
            ...category,
            // Add the new rules field with the list of transformed rules
            rules: matchedRules,
        };
    });
};
