import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogDescription,
} from '@/components/ui/dialog';
import {
    Form,
    FormField,
    FormItem,
    FormControl,
    FormMessage,
    FormLabel,
} from '@/components/common/form';
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
    SelectGroup,
} from '@/components/ui/select';
import { createAdhocInvoiceInvoiceAdHocInvoicesPost } from '@/client/services.gen';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { SelectLabel } from '@radix-ui/react-select';

interface CustomerData {
    label: string;
    value: string;
}

interface InvoiceCreateProps {
    customerData: CustomerData[];
}

const InvoiceCreate: React.FC<InvoiceCreateProps> = ({ customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const createInvoiceAdHocSchema = z.object({
        customerId: z.string({ required_error: 'Customer is required' }),
        dates: z.object(
            {
                from: z.date({ required_error: 'Start date is required' }),
                to: z.date({ required_error: 'End date is required' }),
            },
            { required_error: 'Invoice Period is required' },
        ),
    });

    const form = useForm<z.infer<typeof createInvoiceAdHocSchema>>({
        resolver: zodResolver(createInvoiceAdHocSchema),
        defaultValues: {
            customerId: undefined,
            dates: undefined,
        },
    });

    const { handleSubmit, control } = form;

    const onSubmit = async (
        values: z.infer<typeof createInvoiceAdHocSchema>,
    ) => {
        setIsLoading(true);

        try {
            const body = {
                customer_id: values.customerId,
                start_date: values.dates.from.toISOString(),
                end_date: values.dates.to.toISOString(),
            };
            await createAdhocInvoiceInvoiceAdHocInvoicesPost({
                body: body,
                throwOnError: true,
            });
            setIsDialogOpen(false);
        } catch (error: any) {
            setErrorMessage(error.detail);
        }
        setIsLoading(false);
    };

    const clearForm = () => {
        form.reset();
        setErrorMessage(null);
    };

    return (
        <Dialog
            open={isDialogOpen}
            onOpenChange={(open) => {
                setIsDialogOpen(open);
                clearForm();
            }}
        >
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsDialogOpen(true)}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Create Invoice
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[300px]">
                <DialogHeader>
                    <DialogTitle>Create Invoice</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <div className="text-rails-dark-blue">
                    <Form {...form}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="space-y-8"
                        >
                            <FormField
                                control={control}
                                name="customerId"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Customer</FormLabel>
                                        <Select onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select Customer" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectLabel>
                                                        Customers
                                                    </SelectLabel>
                                                    {customerData
                                                        .sort((c1, c2) =>
                                                            c1.label
                                                                .toLowerCase()
                                                                .localeCompare(
                                                                    c2.label.toLowerCase(),
                                                                ),
                                                        )
                                                        .map((customer) => (
                                                            <SelectItem
                                                                value={
                                                                    customer.value
                                                                }
                                                                key={
                                                                    customer.value
                                                                }
                                                            >
                                                                {customer.label}
                                                            </SelectItem>
                                                        ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="space-y-2">
                                <div className="flex gap-4">
                                    <FormField
                                        control={form.control}
                                        name="dates"
                                        render={({ field }) => (
                                            <FormItem className="flex-1">
                                                <FormLabel>
                                                    Invoice Period
                                                </FormLabel>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <FormControl>
                                                            <Button
                                                                variant={
                                                                    'outline'
                                                                }
                                                                className={cn(
                                                                    'w-full pl-3 text-left font-normal',
                                                                    !field.value &&
                                                                        'text-muted-foreground',
                                                                )}
                                                            >
                                                                <span>
                                                                    {(() => {
                                                                        const dates =
                                                                            form.getValues(
                                                                                'dates',
                                                                            );
                                                                        if (
                                                                            !dates?.from
                                                                        )
                                                                            return 'Select Dates';
                                                                        if (
                                                                            !dates?.to
                                                                        )
                                                                            return (
                                                                                format(
                                                                                    dates.from,
                                                                                    'MMM dd, yyyy',
                                                                                ) +
                                                                                ' -'
                                                                            );
                                                                        return `${format(field.value.from, 'MMM dd, yyyy')} - ${format(field.value.to, 'MMM dd, yyyy')}`;
                                                                    })()}
                                                                </span>
                                                            </Button>
                                                        </FormControl>
                                                    </PopoverTrigger>
                                                    <PopoverContent
                                                        className="w-auto p-0"
                                                        align="start"
                                                    >
                                                        <Calendar
                                                            initialFocus
                                                            mode="range"
                                                            fromYear={2010}
                                                            selected={
                                                                field.value
                                                            }
                                                            onSelect={
                                                                field.onChange
                                                            }
                                                            disabled={(date) =>
                                                                date >
                                                                    new Date() ||
                                                                date <
                                                                    new Date(
                                                                        '2000-01-01',
                                                                    )
                                                            }
                                                            className="rounded-md border"
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <Button type="submit">
                                {isLoading ? 'Creating' : 'Create'}
                            </Button>
                            {errorMessage && (
                                <div className="text-red-500">
                                    {errorMessage}
                                </div>
                            )}
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default InvoiceCreate;
