import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import {
    getRailsWmsDataCountInvoiceAuditsRailsWmsDataCountInvoiceIdGet,
    getMissingChargesInvoiceAuditsMissingChargesInvoiceIdGet
} from '@/client/services.gen';

import type { GetMissingChargesInvoiceAuditsMissingChargesInvoiceIdGetResponse } from "@/client";
import type { RailsWMSDataCount } from '@/client';

import { NumericFormat } from 'react-number-format';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

export default function InvoiceAudits({ 
  invoiceId 
}: {
  invoiceId: string 
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [missingCharges, setMissingCharges] = useState<GetMissingChargesInvoiceAuditsMissingChargesInvoiceIdGetResponse>([]);
  const [invoiceStats, setInvoiceStats] = useState<RailsWMSDataCount>({
      wms_orders: 0,
      wms_packages: 0,
      wms_returns: 0,
      invoice_orders: 0,
      invoice_packages: 0,
      invoice_returns: 0
  })

  useEffect(() => {
      setIsLoading(true);

      if (invoiceId) {
          fetchAudits();
      }
  }, [invoiceId]);

  const fetchAudits = async () => {
    try {
      const counts = await getRailsWmsDataCountInvoiceAuditsRailsWmsDataCountInvoiceIdGet({ path: { invoiceId: invoiceId! } })
      setInvoiceStats(counts.data!)
      const missing = await getMissingChargesInvoiceAuditsMissingChargesInvoiceIdGet({ path: { invoiceId: invoiceId } })
      setMissingCharges(missing.data!)
    } catch (error) {
      console.error('Error fetching invoice stats:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Tabs defaultValue="counts" className="w-full border rounded-lg p-4">
      <TabsList className="mb-2">
        <TabsTrigger value="counts">Invoice vs WMS Stats</TabsTrigger>
        <TabsTrigger value="missing">Missing Charges</TabsTrigger>
      </TabsList>
      <TabsContent value="counts">
        <div className="text-lg font-semibold text-rails-dark-blue">Invoice vs WMS Stats</div>
        <div className="pt-2">
          <DataTable
            loading={isLoading}
            data={[
              { type: 'Orders', invoice: invoiceStats.invoice_orders, wms: invoiceStats.wms_orders },
              { type: 'Packages', invoice: invoiceStats.invoice_packages, wms: invoiceStats.wms_packages },
              { type: 'Returns', invoice: invoiceStats.invoice_returns, wms: invoiceStats.wms_returns }
            ]}
            columns={[
              {
                  accessorKey: 'type',
                  header: 'Type',
                  cell: ({ row }: { row: any }) => (
                      <div className="w-[200px] font-medium">{row.getValue('type')}</div>
                  ),
              },
              {
                  accessorKey: 'invoice',
                  header: 'Invoice Count',
                  cell: ({ row }: { row: any }) => (
                      <div className="flex flex-wrap space-x-2">
                          <NumericFormat value={row.getValue('invoice')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                      </div>
                  ),
              },
              {
                  accessorKey: 'wms',
                  header: 'WMS Count',
                  cell: ({ row }: { row: any }) => (
                      <div className="flex flex-wrap space-x-2">
                          <NumericFormat value={row.getValue('wms')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                      </div>
                  ),
              }
            ]}
            isMultiSelectRows={false}
            showActionButtonInToolbar={false}
            showPagination={false}
            showToolBar={false}
            tableName="invoice-wms-stats"
          />
        </div>
      </TabsContent>
      <TabsContent value="missing">
        <div className="text-lg font-semibold text-rails-dark-blue">Missing Charges</div>
        <div className="pt-2">
          <DataTable
            loading={isLoading}
            data={missingCharges}
            columns={[
              {
                  accessorKey: 'charge_type_name',
                  header: 'Charge Type',
                  cell: ({ row }: { row: any }) => (
                      <div className="w-[200px] font-medium">{row.getValue('charge_type_name')}</div>
                  ),
              },
              {
                  accessorKey: 'last_invoice_id',
                  header: 'Last Invoice ID',
                  cell: ({ row }: { row: any }) => {
                      const invoiceId = row.getValue('last_invoice_id');
                      return invoiceId ? (
                          <Link to={`/invoices/${invoiceId}`} className="text-blue-600 hover:underline">
                              {invoiceId}
                          </Link>
                      ) : (
                          <div>-</div>
                      );
                  },
              },
              {
                  accessorKey: 'last_invoice_date',
                  header: 'Last Invoice Date',
                  cell: ({ row }: { row: any }) => (
                      <div>{row.getValue('last_invoice_date') ? new Date(row.getValue('last_invoice_date')).toLocaleDateString() : ''}</div>
                  ),
              }
            ]}
            isMultiSelectRows={false}
            showActionButtonInToolbar={false}
            showPagination={true}
            showToolBar={false}
            tableName="missing-charges"
          />
        </div>
      </TabsContent>
    </Tabs>
  );
}
