import type { Row } from '@tanstack/react-table';
import { Button } from '../../ui/button';
import { Switch } from '../../ui/switch';
import { TrashIcon } from '@heroicons/react/24/outline';
import { DataTable } from '../../ui/datatable/data-table';
import type { BrandUser } from '@/client';

interface BrandUsersTableProps {
    users: BrandUser[];
    isEnabled: boolean;
    isLoading: boolean;
    onToggleUser: (user: BrandUser) => void;
    onDeleteUser: (user: BrandUser) => void;
}

const BrandUsersTable = ({
    users,
    isEnabled,
    isLoading,
    onToggleUser,
    onDeleteUser,
}: BrandUsersTableProps) => {
    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ row }: { row: Row<BrandUser> }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px]">{row.getValue('name')}</span>
                </div>
            ),
        },
        {
            accessorKey: 'email',
            header: 'Email',
            cell: ({ row }: { row: Row<BrandUser> }) => (
                <div className="flex items-center space-x-2">
                    <span className="w-[200px]">{row.getValue('email')}</span>
                </div>
            ),
        },
        {
            accessorKey: 'is_active',
            header: 'Active',
            cell: ({ row }: { row: Row<BrandUser> }) => (
                <div className="w-[200px]">
                    {isEnabled ? (
                        <Switch
                            checked={row.getValue('is_active')}
                            onCheckedChange={() => {
                                onToggleUser(row.original);
                            }}
                        />
                    ) : (
                        <Switch checked={false} disabled />
                    )}
                </div>
            ),
        },
        {
            id: 'delete',
            cell: ({ row }: { row: Row<BrandUser> }) => (
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                        onDeleteUser(row.original);
                    }}
                >
                    <TrashIcon className="h-4 w-4" data-testid="delete-user" />
                </Button>
            ),
        },
    ];

    return (
        <DataTable
            loading={isLoading}
            data={users}
            columns={columns}
            showPagination={false}
            showToolBar={false}
            tableName="brand users"
        />
    );
};

export default BrandUsersTable;
