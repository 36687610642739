/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from 'react';
import cronstrue from 'cronstrue';

import { SettingsIcon } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from '../../common/card.tsx';
import ConfigDialog from './configDialog.tsx';
import UomConfigDialog from './uomConfigDialog.tsx';
import ProductProfileConfigDialog from './productProfileConfigDialog.tsx';
import ChargeTypeAlerts from './chargeTypeAlerts.tsx';
import LoadingSpinner from '../../common/loadingSpinner.jsx';
import { billingPeriodCutoffTime } from '../../../consts/vars.js';
import {
    getConfigInvoicingGet,
    getInvoiceChargeTypesGet,
} from '@/client/services.gen';
import type { Invoicing, ChargeType } from '@/client/types.gen.ts';

const Configurations = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [configData, setConfigData] = useState<Invoicing>();
    const [chargeTypes, setChargeTypes] = useState<ChargeType[]>([]);

    const fetchInvoicingConfig = async () => {
        setIsLoading(true);
        try {
            const [configResp, chargeTypesResp] = await Promise.all([
                getConfigInvoicingGet(),
                getInvoiceChargeTypesGet(),
            ]);

            if (configResp.error) {
                console.error(configResp.error);
                return;
            }
            if (chargeTypesResp.error) {
                console.error(chargeTypesResp.error);
                return;
            }

            setConfigData(configResp.data);
            setChargeTypes(chargeTypesResp.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInvoicingConfig();
    }, []);

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">
                                Invoicing Configurations
                            </h1>
                            <h2 className="text-xs text-rails-dark-blue">
                                Manage invoice configurations.
                            </h2>
                        </div>
                        <div className="mt-12 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="pt-4">
                                <div className="container mx-auto py-4">
                                    {isLoading && (
                                        <LoadingSpinner loading={isLoading} />
                                    )}
                                    {!isLoading && (
                                        <>
                                            <Card className="w-full">
                                                <CardHeader>
                                                    <CardTitle className="flex items-center">
                                                        Invoicing Configuration
                                                        <SettingsIcon
                                                            className="ml-auto h-5 w-5 hover:text-gray-500"
                                                            onClick={() =>
                                                                setIsDialogOpen(
                                                                    true,
                                                                )
                                                            }
                                                        />
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardContent>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Delivery Cadence
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {configData?.cadence_invoice_delivery
                                                                ? cronstrue
                                                                      .toString(
                                                                          configData?.cadence_invoice_delivery,
                                                                          {
                                                                              verbose:
                                                                                  true,
                                                                          },
                                                                      )
                                                                      .replace(
                                                                          'Every minute, every hour',
                                                                          billingPeriodCutoffTime,
                                                                      )
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Billable Activity Based
                                                        On
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            <div>
                                                                {!configData?.package_billing_date_type &&
                                                                    '-'}
                                                            </div>
                                                            <div>
                                                                {configData?.package_billing_date_type &&
                                                                    (configData?.package_billing_date_type ===
                                                                    'ship'
                                                                        ? 'Shipment date'
                                                                        : 'Order date')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Delivery Method
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {configData?.delivery_method ===
                                                            'erp_quickbooks'
                                                                ? 'Quickbooks'
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Invoice-Level Charges
                                                        Delivery Cadence
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {configData?.cadence_invoice_level_charge_types
                                                                ? cronstrue
                                                                      .toString(
                                                                          configData.cadence_invoice_level_charge_types,
                                                                          {
                                                                              verbose:
                                                                                  true,
                                                                          },
                                                                      )
                                                                      .replace(
                                                                          'Every minute, every hour',
                                                                          billingPeriodCutoffTime,
                                                                      )
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                        Add Carrier Adjustments
                                                    </div>
                                                    <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                        <div>
                                                            {configData?.add_carrier_adjustments
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </div>
                                                    </div>
                                                    {configData?.add_carrier_adjustments && (
                                                        <>
                                                            <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8 mt-3 font-semibold">
                                                                Shipping Rules
                                                                Charge Type
                                                            </div>
                                                            <div className="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-8">
                                                                <div>
                                                                    {configData?.shipping_charge_type_id
                                                                        ? chargeTypes.find(
                                                                              (
                                                                                  ct,
                                                                              ) =>
                                                                                  ct.id ===
                                                                                  configData.shipping_charge_type_id,
                                                                          )
                                                                              ?.name ||
                                                                          '-'
                                                                        : '-'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Card>
                                            <UomConfigDialog />
                                            <ProductProfileConfigDialog />
                                            <ChargeTypeAlerts />
                                        </>
                                    )}
                                </div>
                            </div>
                            {isDialogOpen && !isLoading && (
                                <ConfigDialog
                                    isDialogOpen={isDialogOpen}
                                    setIsDialogOpen={setIsDialogOpen}
                                    fetchConfigs={fetchInvoicingConfig}
                                    initialValues={configData}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Configurations;
