import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
    createBrandBrandIdUsersSignupPost,
    type InvoiceCustomerPublic,
} from '@/client';
import { buildRandomString } from '@/utils/buildRandomString';
import { useState } from 'react';

interface userParams {
    name: string;
    email: string;
    active: boolean;
}

interface CreateBrandUserProps {
    invoiceCustomer: InvoiceCustomerPublic;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    onCreate: () => void;
}

const CreateBrandUser = ({
    invoiceCustomer,
    dialogOpen,
    setDialogOpen,
    onCreate,
}: CreateBrandUserProps) => {
    const [password, setPassword] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [formData, setFormData] = useState<userParams>({
        name: '',
        email: '',
        active: true,
    });

    const handleCreate = async (e: React.FormEvent) => {
        if (password) return;
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        const pw = buildRandomString(24);
        try {
            await createBrandBrandIdUsersSignupPost({
                throwOnError: true,
                path: { brand_id: invoiceCustomer.id! },
                body: {
                    name: formData.name,
                    email: formData.email,
                    password: pw,
                    company_id: invoiceCustomer.company_id,
                    company_name: invoiceCustomer.name,
                    brand_id: invoiceCustomer.id!,
                },
            });
            setPassword(pw);
            onCreate();
        } catch (error: any) {
            setError(error.detail);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleClearForm = () => {
        setFormData({
            name: '',
            email: '',
            active: true,
        });
        setPassword(null);
        setError(null);
    };

    return (
        <Dialog
            open={dialogOpen}
            onOpenChange={(open) => {
                setDialogOpen(open);
                if (!open) {
                    handleClearForm();
                }
            }}
        >
            <DialogTrigger asChild></DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {`Create ${invoiceCustomer.name} Portal User`}
                    </DialogTitle>
                </DialogHeader>
                <form onSubmit={handleCreate} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="name">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleFormChange}
                            required
                        />
                    </div>
                    {error && <div className="text-red-500">{error}</div>}
                    {!password && (
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Creating...' : 'Create'}
                        </Button>
                    )}
                    {password && (
                        <div className="space-y-4">
                            <p>
                                Success! Copy this temporary password now - it
                                won't be shown again.
                            </p>
                            <div className="flex items-center gap-2">
                                <code className="bg-secondary p-2 rounded">
                                    {password}
                                </code>
                            </div>
                            <Button
                                type="button"
                                onClick={() => {
                                    setDialogOpen(false);
                                    handleClearForm();
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    )}
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateBrandUser;
