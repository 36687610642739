import { auth, db } from '@/firebase';
import { client } from '@/client/services.gen';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    type User,
    reauthenticateWithCredential,
    EmailAuthProvider,
    updatePassword as firebaseUpdatePassword,
} from 'firebase/auth';
import { toast } from '@/components/common/use-toast.tsx';
import { useContext, useState, useEffect, useMemo, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendUri } from '@/consts/vars';

interface authContextType {
    currentUser: User | null;
    currentUserCompany: string | null;
    currentUserBrand: string | null;
    loadingAuth: boolean;
    login: (email: string, password: string) => Promise<User>;
    logout: () => void;
    resetPassword: (email: string) => void;
    token: string | null;
    updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    userClaims: object | null;
    userHasClaim: (claim: string) => boolean;
}

const AuthContext = createContext<authContextType | null>(null);

const useAuth = (): authContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// instead of cookies, we use localStorage. This is probably what we should be
// doing. A little research says localStorage is for storing data for the
// browser to use while cookies are sent to the server. In a perfect world, we
// use cookies to pass the auth token to the server since it's being passed
// anyway.
const storage = {
    getUser: (): User | null => {
        const user = localStorage.getItem('currentUser');
        return user ? JSON.parse(user) : null;
    },
    setUser: (user: User | null) => {
        if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
        } else {
            localStorage.removeItem('currentUser');
        }
    },
    getCompany: (): string | null => {
        return localStorage.getItem('currentUserCompany');
    },
    setCompany: (company: string | null) => {
        if (company) {
            localStorage.setItem('currentUserCompany', company);
        } else {
            localStorage.removeItem('currentUserCompany');
        }
    },
    getBrand: (): string | null => {
        return localStorage.getItem('currentUserBrand');
    },
    setBrand: (brand: string | null) => {
        if (brand) {
            localStorage.setItem('currentUserBrand', brand);
        } else {
            localStorage.removeItem('currentUserBrand');
        }
    },
    getToken: (): string | null => {
        return localStorage.getItem('auth_token');
    },
    setToken: (token: string | null) => {
        if (token) {
            localStorage.setItem('auth_token', token);
        } else {
            localStorage.removeItem('auth_token');
        }
    },
    clearAll: () => {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserCompany');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('currentUserBrand');
    },
};

const AuthProvider = ({ children }: { children: any }) => {
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<User | null>(() =>
        storage.getUser(),
    );
    const [currentUserCompany, setCurrentUserCompany] = useState<string | null>(
        () => storage.getCompany(),
    );
    const [currentUserBrand, setCurrentUserBrand] = useState<string | null>(
        () => storage.getBrand(),
    );
    const [token, setToken] = useState<string | null>(() => storage.getToken());
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [userClaims, setUserClaims] = useState<object | null>(null);

    const handleSessionExpiration = () => {
        // Clear user data
        storage.clearAll();
        setCurrentUser(null);
        setCurrentUserCompany(null);
        setToken(null);
        navigate('/', { state: { sessionExpired: true } });
    };

    const getFirebaseToken = async () => {
        try {
            const idToken = await auth.currentUser!.getIdToken(true);
            const idTokenResult = await auth.currentUser!.getIdTokenResult();
            setUserClaims(idTokenResult.claims);
            storage.setToken(idToken);
            setToken(idToken);
        } catch (error) {
            console.error('Error getting token', error);
            handleSessionExpiration();
        }
    };

    const userHasClaim = (claim: string): boolean => {
        if (!currentUser || !userClaims) {
            return false;
        }
        return userClaims.hasOwnProperty(claim);
    };

    const handleLogOut = async () => {
        await signOut(auth);
        handleSessionExpiration();
    };

    const login = async (email: string, password: string) => {
        try {
            setLoadingAuth(true);
            const { user } = await signInWithEmailAndPassword(
                auth,
                email,
                password,
            );
            if (!user) throw new Error('No user returned from Firebase');

            // Get company info
            const userDoc = await getDocs(
                query(
                    collection(db, 'users'),
                    where('user_id', '==', user.uid),
                ),
            ).then((snap) => snap.docs[0]);

            if (!userDoc) throw new Error('User not found in database');

            // Set everything in one go
            storage.setUser(user);
            setCurrentUser(user);
            storage.setCompany(userDoc.data().company_name);
            setCurrentUserCompany(userDoc.data().company_name);
            storage.setBrand(userDoc.data().brand_id);
            setCurrentUserBrand(userDoc.data().brand_id);

            // Get initial token
            const token = await user.getIdToken();
            storage.setToken(token);
            setToken(token);

            setLoadingAuth(false);
            return user;
        } catch (error) {
            setLoadingAuth(false);
            throw error;
        }
    };

    const logout = () => handleLogOut();
    const resetPassword = (email: string) =>
        sendPasswordResetEmail(auth, email);

    const updatePassword = async (oldPassword: string, newPassword: string) => {
        try {
            const user = auth.currentUser;
            if (!user || !user.email)
                throw new Error('No user is currently signed in.');

            const credential = EmailAuthProvider.credential(
                user.email,
                oldPassword,
            );
            await reauthenticateWithCredential(user, credential);
            await firebaseUpdatePassword(user, newPassword);
        } catch (error) {
            throw error;
        }
    };

    // NOTE: these functions seem important
    // Set up Firebase auth state listener
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                storage.setUser(user);
                setCurrentUser(user);
                getFirebaseToken();
            } else {
                handleSessionExpiration();
            }
        });

        setLoadingAuth(false);

        return () => {
            unsubscribe();
        };
    }, []);

    // NOTE: this is important
    //Token refresh logic
    useEffect(() => {
        if (!currentUser) return;

        const tokenInterval = setInterval(getFirebaseToken, 10 * 60 * 1000); // every 10 minutes

        return () => clearInterval(tokenInterval);
    }, [currentUser]);

    useEffect(() => {
        if (!token) return;

        // configure internal service client
        client.setConfig({
            baseUrl: backendUri,
            // set default headers for requests
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${storage.getToken()}` : '',
            },
        });

        const allowed = [200, 204]

        client.interceptors.response.use((response) => {
            if (allowed.includes(response.status)) {
                return response;
            } else {
                switch (response.status) {
                    case 401:
                        toast({
                            variant: 'destructive',
                            title: 'Unauthorized ' + response.status,
                            description: 'Please log in again.',
                        });
                        break;
                    case 403:
                        toast({
                            variant: 'destructive',
                            title: 'Forbidden ' + response.status,
                            description:
                                'You do not have access to this resource.',
                        });
                        break;
                    case 404:
                        toast({
                            variant: 'destructive',
                            title: 'Not Found ' + response.status,
                            description:
                                'The requested resource could not be found.',
                        });
                        break;
                    case 500:
                        toast({
                            variant: 'destructive',
                            title: 'Server response ' + response.status,
                            description:
                                'An internal server response occurred. Please try again later.',
                        });
                        break;
                    case 422:
                        toast({
                            variant: 'destructive',
                            title: response.statusText + ' ' + response.status,
                            description: response.statusText,
                        });
                        break;
                    default:
                        toast({
                            variant: 'destructive',
                            title: response.statusText + ' ' + response.status,
                        });
                        break;
                }
            }

            return response;
        });
    }, [token]);

    const value = useMemo(
        () => ({
            currentUser,
            currentUserCompany,
            currentUserBrand,
            loadingAuth,
            login,
            logout,
            resetPassword,
            token,
            updatePassword,
            userClaims,
            userHasClaim,
        }),
        [
            currentUser,
            currentUserCompany,
            currentUserBrand,
            loadingAuth,
            token,
            userClaims,
        ],
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export { AuthProvider, useAuth };
