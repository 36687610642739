import { toast } from '@/components/common/use-toast.tsx';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';

import Invoices from './Invoices';
import { useEffect, useState } from 'react';
import { readByIdV2WmsInvoiceCustomersIdGet } from '@/client';
import LoadingSpinner from '@/components/common/loadingSpinner';

const Portal = () => {
    const { logout } = useAuth();
    const { brandId } = useParams<{ brandId: string }>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);

    useEffect(() => {
        const fetchBrand = async () => {
            try {
                const response = await readByIdV2WmsInvoiceCustomersIdGet({
                    throwOnError: true,
                    path: { id: brandId! },
                });
                setIsEnabled(response.data.portal_enabled!);
                if (!response.data.portal_enabled) {
                    logout();
                    navigate('/');
                    toast({
                        variant: 'destructive',
                        title: 'Brand portal disabled',
                    });
                }
            } catch (error) {
                throw new Error('Error fetching brand');
            } finally {
                setIsLoading(false);
            }
        };
        fetchBrand();
    }, []);

    return isLoading ? (
        <div className="flex items-center justify-center min-h-[400px]">
            <div className="flex flex-col items-center gap-2">
                <LoadingSpinner loading={true} className="h-8 w-8" />
                <div className="text-sm text-gray-500">Loading data...</div>
            </div>
        </div>
    ) : (
        <Invoices />
    );
};

export default Portal;
