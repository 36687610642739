import { useState, useEffect } from 'react';

import { toast } from '@/components/common/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Switch } from '@/components/ui/switch';

import {
    readManyV2ProductsProfilesGet,
    readManyV2WmsCustomersGet,
    readProductsV2ProductsGet,
    updateProductV2ProductsProductIdPatch,
} from '@/client/services.gen.ts';

import ProductSearch from './productSearch';
import { Button } from '@/components/ui/button';
import {
    type Product,
    type ProductProfile,
    type ProductPublic,
    type WMSCustomer,
} from '@/client/types.gen.js';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';

const Products = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<ProductPublic[]>([]);
    const [searchOpen, setSearchOpen] = useState<boolean>(false);
    const [customers, setCustomers] = useState<WMSCustomer[]>([]);
    const [allProducts, setAllProducts] = useState<ProductPublic[]>([]);
    const [profiles, setProfiles] = useState<ProductProfile[]>([]);

    const fetchCustomers = async () => {
        setIsLoading(true);
        try {
            const response = await readManyV2WmsCustomersGet({
                throwOnError: true,
            });
            setCustomers(response.data);
        } catch (error) {
            console.log(error);
            throw new Error('Error fetching customers');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await readManyV2ProductsProfilesGet({
                throwOnError: true,
            });
            setProfiles(response.data);
        } catch (error) {
            console.log(error);
            throw new Error('Error fetching profiles');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProducts = async (
        searchQuery: Record<string, unknown> = {},
    ): Promise<ProductPublic[]> => {
        setIsLoading(true);
        // Add limit to query
        const queryWithLimit = {
            ...searchQuery,
            limit: 1000,
        };
        try {
            const response = await readProductsV2ProductsGet({
                query: queryWithLimit,
                throwOnError: true,
            });

            const data = response.data;
            if (!data || data.length === 0) {
                if (Object.keys(searchQuery).length > 0) {
                    // Only show "no products" toast if it's a search
                    toast({
                        variant: 'destructive',
                        title: 'Warning',
                        description: 'No products found',
                        action: (
                            <ToastAction altText="I Understand">
                                I Understand
                            </ToastAction>
                        ),
                    });
                }
            }
            if (data.length >= 1000) {
                toast({
                    variant: 'destructive',
                    title: 'Warning',
                    description: 'Maximum limit of 1000 products reached',
                    action: (
                        <ToastAction altText="I Understand">
                            I Understand
                        </ToastAction>
                    ),
                });
            }
            setIsLoading(false);
            return data;
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            throw new Error('Failed to fetch data');
        }
    };

    const handleProductProfileChange = async (
        record: ProductPublic,
        profileId: string,
    ): Promise<ProductPublic> => {
        const { uom_links, wms_customer, profile, ...rest } = record;
        const product: Product = {
            ...rest,
            profile_id: profileId === 'null' ? null : profileId,
            wms_customer_id: wms_customer!.id!,
        };
        try {
            const response = await updateProductV2ProductsProductIdPatch({
                body: product,
                path: { product_id: product.id! },
                throwOnError: true,
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to update product');
        }
    };

    const handleProductSearch = async (
        searchQuery: Record<string, unknown>,
    ) => {
        setSearchOpen(false);
        // Convert profile_id from single value to array if it exists
        const modifiedQuery = {
            ...searchQuery,
        };
        const products = await fetchProducts(modifiedQuery);
        setTableData(products);
        setSearchOpen(false);
    };

    const resetTable = async () => {
        if (allProducts.length !== 0) {
            setTableData(allProducts);
            setSearchOpen(false);
        } else {
            const products = await fetchProducts();
            setAllProducts(products);
            setTableData(products);
            setSearchOpen(false);
        }
    };

    useEffect(() => {
        const setInitialTableData = async () => {
            const products = await fetchProducts();
            setAllProducts(products);
            setTableData(products);
        };

        setInitialTableData();
        fetchCustomers();
        fetchProfiles();
    }, []);

    const columns = [
        {
            accessorKey: 'sku',
            header: 'SKU',
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px] font-medium">
                    {row.getValue('sku')}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'wms_customer',
            header: 'Customer',
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px]">
                    {row.getValue('wms_customer')?.name}
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'is_kit',
            header: 'Is a Kit',
            cell: ({ row }: { row: any }) => (
                <div className="z-99 w-[80px]">
                    <Switch checked={row.getValue('is_kit')} disabled />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'exclude_from_pick_rate',
            header: 'Excluded from Pricing',
            cell: ({ row }: { row: any }) => (
                <div className="z-99 w-[80px]">
                    <Switch
                        checked={row.getValue('exclude_from_pick_rate')}
                        disabled
                    />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'profile',
            header: 'Product Profile',
            cell: ({ row }: { row: any }) => {
                const currentValue = row.getValue('profile')?.name ?? 'None';
                return (
                    <Select
                        onValueChange={async (value) => {
                            try {
                                const product =
                                    await handleProductProfileChange(
                                        row.original,
                                        value,
                                    );
                                row.setValue('profile', product.profile);
                            } catch (error) {
                                throw new Error('Failed to update profile');
                            }
                        }}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder={currentValue} />
                        </SelectTrigger>
                        <SelectContent>
                            {profiles.map((profile) => (
                                <SelectItem
                                    key={profile.id!}
                                    value={profile.id!}
                                >
                                    {profile.name}
                                </SelectItem>
                            ))}
                            <SelectItem value="null">None</SelectItem>
                        </SelectContent>
                    </Select>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const uomColumns = [
        {
            accessorKey: 'uom_name',
            header: 'UOM',
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px]">{row.getValue('uom_name')}</div>
            ),
        },
        {
            accessorKey: 'quantity',
            header: 'Quantity of Eaches',
            cell: ({ row }: { row: any }) => (
                <div className="w-[100px]">{row.getValue('quantity')}</div>
            ),
        },
    ];

    const TableActionButton = () =>
        searchOpen ? null : (
            <Button onClick={() => setSearchOpen(true)}>Search products</Button>
        );

    return (
        <div
            className="h-screen flex overflow-hidden bg-white"
            data-testid="products"
        >
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="grid grid-cols-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="col-span-3">
                                <h1 className="text-2xl font-bold text-rails-dark-blue">
                                    Products
                                </h1>
                                <h2 className="text-xs text-rails-dark-blue">
                                    Manage products
                                </h2>
                            </div>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-5">
                            <div className="relative">
                                {searchOpen && (
                                    <div className="absolute inset-0 z-10 bg-white bg-opacity-75">
                                        <ProductSearch
                                            open={searchOpen}
                                            setOpen={setSearchOpen}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            onSearchQuery={handleProductSearch}
                                            resetTable={resetTable}
                                            customers={customers}
                                            profiles={profiles}
                                        />
                                    </div>
                                )}
                                <div className="container mx-auto py-5">
                                    {allProducts && (
                                        <DataTable
                                            loading={isLoading}
                                            data={tableData}
                                            columns={columns}
                                            isMultiSelectRows={false}
                                            ActionButton={<TableActionButton />}
                                            showActionButtonInToolbar
                                            tableName="packages"
                                            collapsible
                                            collapsibleContent={({ row }) => {
                                                if (row?.uom_links) {
                                                    return (
                                                        <DataTable
                                                            loading={isLoading}
                                                            data={row.uom_links}
                                                            columns={uomColumns}
                                                            isMultiSelectRows={
                                                                false
                                                            }
                                                            showPagination={
                                                                false
                                                            }
                                                            showToolBar={false}
                                                            showActionButtonInToolbar={
                                                                false
                                                            }
                                                            showActionButtonInCollapsible
                                                            collapsible={false}
                                                        />
                                                    );
                                                }
                                                return null;
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Products;
