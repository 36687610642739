import { useEffect, useState } from 'react';
import {
    updateV2WmsInvoiceCustomersIdPut,
    setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost,
} from '@/client/services.gen';
import { Switch } from '@/components/ui/switch';
import { Button } from '@/components/ui/button';
import * as dialog from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
    type InvoiceCustomerPublic,
    type WMSCustomer,
} from '@/client/types.gen';
import {
    Form,
    FormField,
    FormItem,
    FormControl,
    FormMessage,
    FormLabel,
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import Select from 'react-select';
import { evaluateFeatureFlag } from '@/lib/remoteConfig';

export interface EditCustomerProps {
    invoiceCustomer: InvoiceCustomerPublic | null;
    wmsCustomers: WMSCustomer[];
    isEditDialogOpen: boolean;
    setIsEditDialogOpen: (open: boolean) => void;
    onEditSuccess: () => void;
}

const EditCustomer = ({
    invoiceCustomer,
    wmsCustomers,
    isEditDialogOpen,
    setIsEditDialogOpen,
    onEditSuccess,
}: EditCustomerProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showMeteredBilling, setShowMeteredBilling] = useState(false);

    // useEffect(() => {
    //     evaluateFeatureFlag('metered_billing').then(setShowMeteredBilling);
    // }, []);

    const customerSchema = z.object({
        name: z.string().min(1, 'Name is required'),
        active: z.boolean(),
        metered_billing_enabled: z.boolean(),
        fulfillment_deposit: z.number().nullable(),
        fulfillment_deposit_threshold: z.number().nullable(),
        wmsCustomerIds: z.array(z.string()).nullable(),
    });
    const form = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        defaultValues: {
            name: invoiceCustomer?.name ?? '',
            active: invoiceCustomer?.active ?? true,
            metered_billing_enabled: invoiceCustomer?.metered_billing_enabled ?? false,
            fulfillment_deposit: invoiceCustomer?.fulfillment_deposit ?? null,
            fulfillment_deposit_threshold: invoiceCustomer?.fulfillment_deposit_threshold ?? null,
            wmsCustomerIds: invoiceCustomer?.wms_customers.map((c) => c.id),
        },
    });

    if (!invoiceCustomer) {
        return null;
    }

    const onSubmit = async (values: z.infer<typeof customerSchema>) => {
        try {
            setIsSubmitting(true);
            await updateV2WmsInvoiceCustomersIdPut({
                path: { id: invoiceCustomer.id! },
                body: {
                    name: values.name,
                    active: values.active,
                    metered_billing_enabled: values.metered_billing_enabled,
                    fulfillment_deposit: values.fulfillment_deposit,
                    fulfillment_deposit_threshold: values.fulfillment_deposit_threshold,
                    company_id: invoiceCustomer.company_id,
                    email: invoiceCustomer.email,
                },
            });

            if (values.wmsCustomerIds?.length) {
                await Promise.all(
                    values.wmsCustomerIds.map((id) =>
                        setInvoiceCustomerIdV2WmsCustomersIdInvoiceCustomerPost(
                            {
                                path: { id },
                                query: {
                                    invoice_customer_id: invoiceCustomer.id!,
                                },
                            },
                        ),
                    ),
                );
            }
            onEditSuccess();
            setIsEditDialogOpen(false);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (invoiceCustomer) {
            form.reset({
                name: invoiceCustomer.name ?? '',
                active: invoiceCustomer.active ?? true,
                metered_billing_enabled: invoiceCustomer.metered_billing_enabled ?? true,
                fulfillment_deposit: invoiceCustomer?.fulfillment_deposit ? parseFloat(String(invoiceCustomer.fulfillment_deposit)) : null,
                fulfillment_deposit_threshold: invoiceCustomer?.fulfillment_deposit_threshold ? parseFloat(String(invoiceCustomer.fulfillment_deposit_threshold)) : null,
                wmsCustomerIds: invoiceCustomer.wms_customers.map((c) => c.id),
            });
        }
    }, [invoiceCustomer, form]);

    return (
        <dialog.Dialog
            open={isEditDialogOpen}
            onOpenChange={setIsEditDialogOpen}
        >
            <dialog.DialogContent>
                <dialog.DialogHeader>
                    <dialog.DialogTitle>Edit Customer</dialog.DialogTitle>
                </dialog.DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-8"
                    >
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="active"
                            render={({ field }) => (
                                <FormItem>
                                    <div className="flex items-center space-x-2">
                                        <FormControl>
                                            <Switch
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                        <FormLabel>Active</FormLabel>
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {!showMeteredBilling && (
                            <FormField
                                control={form.control}
                                name="metered_billing_enabled"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="flex items-center space-x-2">
                                            <FormControl>
                                                <Switch
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                            <FormLabel>Metered Billing</FormLabel>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )}                        
                        {form.watch('metered_billing_enabled') && (
                            <div className="grid grid-cols-2 gap-4">
                              <FormField
                                  control={form.control}
                                  name="fulfillment_deposit"
                                  render={({ field }) => (
                                      <FormItem>
                                          <FormLabel>Fulfillment Deposit ($)</FormLabel>
                                          <FormControl>
                                              <Input
                                                  type="number"
                                                  step="0.01"
                                                  value={field.value ?? ''}
                                                  onChange={(e) => field.onChange(e.target.value ? parseFloat(e.target.value) : null)}
                                              />
                                          </FormControl>
                                          <FormMessage />
                                      </FormItem>
                                  )}
                              />
                              <FormField
                                  control={form.control}
                                  name="fulfillment_deposit_threshold"
                                  render={({ field }) => (
                                      <FormItem>
                                          <FormLabel>Fulfillment Deposit Threshold (%)</FormLabel>
                                          <FormControl>
                                              <div className="relative">
                                                  <Input
                                                      type="number"
                                                      min="0"
                                                      max="100"
                                                      step="1"
                                                      value={field.value ?? ''}
                                                      onChange={(e) => field.onChange(e.target.value ? parseFloat(e.target.value) : null)}
                                                  />
                                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                      <span className="text-gray-500">%</span>
                                                  </div>
                                              </div>
                                          </FormControl>
                                          <FormMessage />
                                      </FormItem>
                                  )}
                              />
                            </div>
                        )}
                        <FormField
                            control={form.control}
                            name="wmsCustomerIds"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>WMS Customers</FormLabel>
                                    <FormControl>
                                        <Select
                                            isMulti
                                            value={wmsCustomers
                                                .filter((c) =>
                                                    field.value?.includes(
                                                        c.id!,
                                                    ),
                                                )
                                                .map((c) => ({
                                                    value: c.id,
                                                    label: c.name,
                                                }))}
                                            onChange={(options) =>
                                                field.onChange(
                                                    options?.map(
                                                        (opt) => opt.value,
                                                    ) ?? [],
                                                )
                                            }
                                            options={wmsCustomers.map((c) => ({
                                                value: c.id,
                                                label: c.name,
                                            }))}
                                            className="w-full"
                                            classNamePrefix="select"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Updating...' : 'Update Customer'}
                        </Button>
                    </form>
                </Form>
            </dialog.DialogContent>
        </dialog.Dialog>
    );
};

export default EditCustomer;
