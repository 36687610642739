import { useState, useEffect, useRef } from 'react';
import {
    getChargeDescriptionsCarrierBillsChargeDescriptionsGet,
    statsCarrierBillsStatsGet,
    tableDataDownloadCarrierBillsReportsChargeDetailJsonGet,
    invoiceDetailCarrierBillsReportsInvoiceDetailGet,
    getCarriersCarrierBillsCarriersGet,
} from '@/client/services.gen';
import { type StatsCarrierBillsStatsGetResponse, Aggregation, Period, ColSelect, ColAggBy, type InvoiceDetailRow, type ChargeDescription, type Carrier } from '@/client/types.gen';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, subWeeks } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { BarChartCard } from '@/components/ui/charts/bar';
import { DataTable } from '@/components/ui/datatable/data-table';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import LoadingSpinner from '@/components/ui/loading-spinner';

export default function CarrierSpend() {
    const [stats, setStats] = useState<StatsCarrierBillsStatsGetResponse>();
    const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailRow[]>([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [downloadStartDate, setDownloadStartDate] = useState<Date>(subWeeks(new Date(), 1));
    const [downloadEndDate, setDownloadEndDate] = useState<Date>(new Date());
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [selectedCarrierNames, setSelectedCarrierNames] = useState<string[]>([]);
    const [selectedCarrier, setSelectedCarrier] = useState<string[]>([]);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [chargeDescriptions, setChargeDescriptions] = useState<ChargeDescription[]>([]);
    const [selectedChargeDescriptions, setSelectedChargeDescriptions] = useState<string[]>([]);
    const [filteredChargeDescriptions, setFilteredChargeDescriptions] = useState<ChargeDescription[]>([]);
    const initialFetchDone = useRef(false);

    const getDefaultDates = () => ({
        start_date: subWeeks(new Date(), 6).toISOString(),
        end_date: new Date().toISOString(),
    });

    const handleFetchInvoiceDetails = async (params: any) => {
        setIsTableLoading(true);
        const dates = getDefaultDates();
        const response = await invoiceDetailCarrierBillsReportsInvoiceDetailGet({
            query: {
                start_date: params.start_date || dates.start_date,
                end_date: params.end_date || dates.end_date,
            },
        });
        const sortedData = Array.isArray(response.data) ? response.data.sort((a, b) => new Date(b.invoice_date).getTime() - new Date(a.invoice_date).getTime()) : [];
        setInvoiceDetails(sortedData);
        setIsTableLoading(false);
    };

    const handleFetchStats = async (params: any) => {
        setIsChartLoading(true);
        setError(null);
        try {
            const dates = getDefaultDates();
            const response = await statsCarrierBillsStatsGet({
                query: {
                    col_select: params.col_select || 'CHARGE_AMOUNT',
                    col_agg_by: params.col_agg_by || 'CARRIER_NAME',
                    type_agg: params.type_agg || 'sum',
                    type_date: params.type_date || 'week',
                    start_date: params.start_date || dates.start_date,
                    end_date: params.end_date || dates.end_date,
                },
            });

            setStats(response.data);

            return response;
        } catch (error) {
            console.error('Error fetching stats:', error);
            setError('Failed to fetch stats. Please try again.');
            return null;
        } finally {
            setIsChartLoading(false);
        }
    };

    useEffect(() => {
        if (initialFetchDone.current) return;

        const fetchData = async () => {
            try {
                setIsInitialLoading(true);
                const dates = getDefaultDates();

                // Get charge descriptions to find active carrier IDs
                const chargeDescResponse = await getChargeDescriptionsCarrierBillsChargeDescriptionsGet();
                const activeCarrierIds = new Set(chargeDescResponse.data?.map(desc => desc.carrier_id) || []);
                
                // Set charge descriptions
                if (chargeDescResponse.data) {
                    setChargeDescriptions(chargeDescResponse.data);
                }

                // Fetch carriers and filter to only those with charge descriptions
                const carriersResponse = await getCarriersCarrierBillsCarriersGet();
                if (carriersResponse.data) {
                    const filteredCarriers = carriersResponse.data.filter(carrier => 
                        activeCarrierIds.has(carrier.id)
                    );
                    setCarriers(filteredCarriers);
                }

                await handleFetchInvoiceDetails(dates);
                await handleFetchStats(dates);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsInitialLoading(false);
                initialFetchDone.current = true;
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedCarrier.length === 0) {
            setFilteredChargeDescriptions(chargeDescriptions);
            return;
        }

        const filtered = chargeDescriptions.filter(desc => 
            selectedCarrier.includes(desc.carrier_id)
        );
        
        setSelectedChargeDescriptions(prev => 
            prev.filter(desc => 
                filtered.some(filteredDesc => filteredDesc.description === desc)
            )
        );
        
        setFilteredChargeDescriptions(filtered);
    }, [selectedCarrier, chargeDescriptions]);

    const handleDownload = async () => {
        setIsDownloading(true);
        setError(null);
        try {
            const response = await tableDataDownloadCarrierBillsReportsChargeDetailJsonGet({
                query: {
                    start_date: downloadStartDate.toISOString(),
                    end_date: downloadEndDate.toISOString(),
                    carrier: selectedCarrierNames.length > 0 ? selectedCarrierNames : null,
                    description: selectedChargeDescriptions.length > 0 ? selectedChargeDescriptions : null,
                },
            });

            // Create a Blob from the response
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

            // Create a link element and trigger the download
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', 'carrier_bill_report.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setSuccessMessage('Download successful.');
            }
        } catch (error) {
            setError('Failed to download report. Please try again.');
        } finally {
            setIsDownloading(false);
        }
    };

    const invoiceDetailsColumns = [
        {
            accessorKey: 'carrier',
            header: 'Carrier',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('carrier')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'invoice_number',
            header: 'Invoice Number',
            enableColumnFilter: true,
            filterType: 'text', 
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('invoice_number')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'account_number',
            header: 'Account Number',
            enableColumnFilter: true,
            filterType: 'text', 
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('account_number')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'invoice_date',
            header: 'Invoice Date',
            enableColumnFilter: true,
            filterType: 'date',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{new Date(row.getValue('invoice_date')).toLocaleDateString()}</div>,
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'total_charge_amount',
            header: 'Total Charge',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('total_charge_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const chargeDetailsColumns = [
        {
            accessorKey: 'charge_description',
            header: 'Charge Description',
            enableColumnFilter: true,
            filterType: 'text',
            cell: ({ row }: { row: any }) => <div className="w-[200px]">{row.getValue('charge_description')}</div>,
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_quantity',
            header: 'Quantity',
            enableColumnFilter: true,
            filterType: 'number',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('charge_quantity')} displayType="text" thousandSeparator="," decimalScale={0} />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'charge_amount',
            header: 'Charge',
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px]">
                    <NumericFormat value={row.getValue('charge_amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const InvoiceDetailsTable = () => (
        <div className="mt-8">
            <div className="mb-4">
                <h2 className="text-lg font-bold">List of Invoices</h2>
                <p className="text-sm text-gray-500">Invoice details for all carriers processed in the last 6 weeks</p>
            </div>
            <DataTable
                data={invoiceDetails}
                columns={invoiceDetailsColumns}
                loading={isTableLoading}
                collapsible
                showActionButtonInToolbar
                showActionButtonInCollapsible={false}
                showToolBar
                isDownloadable={true}
                downloadData={invoiceDetails.map(({ charges, ...rest }) => rest)}
                tableName="invoice_details"
                collapsibleContent={({ row }) => {
                    if (row?.charges) {
                        return (
                            <DataTable
                                loading={isTableLoading}
                                data={row.charges}
                                columns={chargeDetailsColumns}
                                isMultiSelectRows={false}
                                showPagination={false}
                                showToolBar={true}
                                showActionButtonInToolbar={false}
                                showActionButtonInCollapsible
                                collapsible={false}
                                isDownloadable={true}
                                downloadData={row.charges}
                                tableName="invoice_details_charges"
                            />
                        );
                    }
                    return null;
                }}
            />
        </div>
    );

    return (
        <div className="p-4">
            {isInitialLoading ? (
                <div className="flex items-center justify-center min-h-[400px]">
                    <div className="flex flex-col items-center gap-2">
                        <LoadingSpinner loading={true} className="h-8 w-8" />
                        <div className="text-sm text-gray-500">Loading data...</div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex justify-end">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button>
                                    <div className="pr-2">
                                        <ArrowDownTrayIcon className="w-4 h-4" />
                                    </div>
                                    <div>Export</div>
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>Export Reports</DialogTitle>
                                </DialogHeader>
                                <Tabs defaultValue="download">
                                    <TabsList className="grid w-full grid-cols-2">
                                        <TabsTrigger value="download">Download</TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="download">
                                        <form className="space-y-4">
                                            <div className="flex space-x-4">
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button variant="outline">{format(downloadStartDate, 'PP')}</Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar mode="single" selected={downloadStartDate} onSelect={(date) => date && setDownloadStartDate(date)} />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button variant="outline">{format(downloadEndDate, 'PP')}</Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0">
                                                            <Calendar mode="single" selected={downloadEndDate} onSelect={(date) => date && setDownloadEndDate(date)} />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                            </div>
                                            <div className="space-y-2">
                                                <label className="block text-sm font-medium text-gray-700">Carrier</label>
                                                <Select
                                                    isMulti={true}
                                                    value={carriers
                                                        .filter((c) => selectedCarrier.includes(c.id))
                                                        .map((c) => ({
                                                            value: c.id,
                                                            label: c.name,
                                                        }))}
                                                    onChange={(newValue: any) => {
                                                        if (newValue?.some((option: any) => option.value === 'select-all')) {
                                                            const allCarrierIds = carriers.map(c => c.id);
                                                            const allCarrierNames = carriers.map(c => c.name);
                                                            setSelectedCarrier(allCarrierIds);
                                                            setSelectedCarrierNames(allCarrierNames);
                                                        } else {
                                                            const ids = newValue ? newValue.map((v: any) => v.value) : [];
                                                            const names = newValue ? carriers
                                                                .filter(c => ids.includes(c.id))
                                                                .map(c => c.name) : [];
                                                            setSelectedCarrier(ids);
                                                            setSelectedCarrierNames(names);
                                                        }
                                                    }}
                                                    options={[
                                                        {
                                                            label: "Select All Carriers",
                                                            value: "select-all"
                                                        },
                                                        ...carriers.map(carrier => ({
                                                            value: carrier.id,
                                                            label: carrier.name
                                                        }))
                                                    ]}
                                                    className="w-full"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select carriers"
                                                    isClearable
                                                />
                                            </div>
                                            <div className="space-y-2">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Charge Descriptions
                                                </label>
                                                <Select
                                                    isMulti
                                                    value={selectedChargeDescriptions.map(desc => ({
                                                        value: desc,
                                                        label: desc
                                                    }))}
                                                    onChange={(options) => setSelectedChargeDescriptions(options ? options.map(opt => opt.value) : [])}
                                                    options={filteredChargeDescriptions.map(desc => ({
                                                        value: desc.description,
                                                        label: desc.description
                                                    }))}
                                                    className="w-full"
                                                    classNamePrefix="react-select"
                                                    placeholder={selectedCarrier.length === 0 
                                                        ? "Select carriers first to filter charge descriptions" 
                                                        : `${filteredChargeDescriptions.length} descriptions available`}
                                                    isClearable
                                                    isDisabled={selectedCarrier.length === 0}
                                                />
                                            </div>
                                            <Button onClick={handleDownload} disabled={isDownloading}>
                                                {isDownloading ? 'Downloading...' : 'Download'}
                                            </Button>
                                            {successMessage && <p className="text-green-500">{successMessage}</p>}
                                            {error && <p className="text-red-500">{error}</p>}
                                        </form>
                                    </TabsContent>
                                </Tabs>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <BarChartCard
                        title="Carrier Spend and Utilization"
                        description="Analyze carrier costs and usage"
                        data={stats || []}
                        dataKeys={[]}
                        xAxisDataKey="date"
                        onFetchData={handleFetchStats}
                        isLoading={isChartLoading}
                        error={error}
                        aggregateOptions={Object.entries(ColAggBy).reduce((acc, [key, value]) => ({
                            ...acc,
                            [value]: value
                        }), {})}
                        columnOptions={Object.entries(ColSelect).reduce((acc, [key, value]) => ({
                            ...acc,
                            [value]: value
                        }), {})}
                        aggregationTypeOptions={Object.entries(Aggregation).reduce((acc, [key, value]) => ({
                            ...acc,
                            [value]: value
                        }), {})}
                        dateTypeOptions={Object.entries(Period).reduce((acc, [key, value]) => ({
                            ...acc,
                            [value]: value
                        }), {})}
                        initialParams={{
                            col_select: ColSelect.CHARGE_AMOUNT,
                            col_agg_by: ColAggBy.CARRIER_NAME,
                            type_agg: Aggregation.SUM.toLowerCase(),
                            type_date: Period.WEEK.toLowerCase(),
                            start_date: subWeeks(new Date(), 6).toISOString(),
                            end_date: new Date().toISOString(),
                        }}
                    />
                    <InvoiceDetailsTable />
                </>
            )}
        </div>
    );
}
