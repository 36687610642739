import { useState, useEffect } from 'react';
import { 
  type AlertMatch,
  getMatchingAlertsConfigAlertsMatchingAlertResourceGet 
} from "@/client"
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'

export default function AlertNotifications() {
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState<AlertMatch[]>()

  useEffect(() => {
    fetchAlerts()
  }, [])

  const fetchAlerts = async () => {
      const { data, error } = await getMatchingAlertsConfigAlertsMatchingAlertResourceGet({ path: { alert_resource: 'charge_type' } });
      setAlerts(data)
      if (error) {
          console.log(error)
      } else {
          setIsLoading(false)
      }
  }

  if (isLoading) {
      return (
          <div className="h-screen flex items-center justify-center">
              <p className="text-xl">Loading...</p>
          </div>
      );
  }

  return (<div>
    {(alerts && alerts.length > 0) && <div className="mx-8">
        {alerts.map(a => <Alert variant="default" className="space-y-2 mt-4">
            <AlertTitle key={a.alert.id} className="text-lg font-semibold">
              {(() => {
                switch(a.alert.alert_resource) {
                  case 'charge_type':
                    return `Charge Type Alert for ${a.name}`
                  default:
                    return ''
                }
              })()} 
            </AlertTitle>
            <AlertDescription>
                <p className="text-sm mb-3">
                  {a.alert.alert_config.apply_to_sum && <span>The sum of invoice {a.invoice_id} exceeds ${a.alert.alert_config.amount.toFixed(2)}</span>}
                  {a.alert.alert_config.apply_to_line_item && <span>A line item from invoice {a.invoice_id} exceeds ${a.alert.alert_config.amount.toFixed(2)}</span>}
                </p>
                <div className="mt-4">
                    <a href={`/invoices/${a.invoice_id}`}>
                        <Button variant="outline" size="sm">
                          View invoice
                        </Button>
                    </a>
                </div>
            </AlertDescription>
        </Alert>)}
    </div>}
  </div>)
}
